import apiEndPoints from "../../apiconstants";
import { api, handleErrorResponse } from "../api";


export const promptApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getPromptInstructions: builder.query<any, string>({
            query: (advisorID) => `${apiEndPoints?.getPromptInstructions}/${advisorID}`,
            transformResponse: (response: any) => response.data,
            transformErrorResponse: handleErrorResponse,
        }),
        updatePromptSettings: builder.mutation<any, any>({
            query: (payLoad) => ({
                url: `${apiEndPoints?.updatePromptSettings}/${payLoad.advisorID}`,
                method: 'PATCH',
                body: payLoad.prompt_settings,
            }),
            transformErrorResponse: handleErrorResponse
        })
    })
});


export const { useGetPromptInstructionsQuery, useUpdatePromptSettingsMutation } = promptApiSlice;