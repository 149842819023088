import { createSlice } from '@reduxjs/toolkit';


export const GLBOAL_CART_SLICE_FEATURE_KEY = 'globalCartSlice';

export interface PartDetailsState {
    partNumber: string;
    partName: string;
}

export interface ItemExtraDetails {
    quantity: number;
}

export interface ItemDetailsState {
    totalQuanity: number;
    id: string;
    inCart: boolean;
    partNumber: string;
    partName: string;
    extra?: { [key: string]: ItemExtraDetails; };
}

export interface GlobalCartSliceState {
    cartID: string;
    itemsList: { [key: string]: ItemDetailsState; };
}

export const initialGlobalCartSliceState: GlobalCartSliceState = {
    itemsList: {},
    cartID: ''
};

export const globalCartSliceSlice = createSlice({
    name: GLBOAL_CART_SLICE_FEATURE_KEY,
    initialState: initialGlobalCartSliceState,
    reducers: {
        addItemToCart(state, action) {
            const { partDetails, uniqueIdentifier } = action.payload;
            const { partNumber, partName, id, cartID, quantity: currQuantity } = partDetails;
            const { totalQuanity: prevQuantity = 0 } = state.itemsList[partNumber] || {};

            if (!state.itemsList?.[partNumber]) {
                state.itemsList[partNumber] = {
                    inCart: true,
                    id,
                    partName,
                    partNumber,
                    totalQuanity: Number(prevQuantity) + Number(currQuantity),
                    [uniqueIdentifier]: {
                        quantity: Number(currQuantity)
                    }
                };
            } else {
                state.itemsList[partNumber].totalQuanity = Number(prevQuantity) + Number(currQuantity);
                state.itemsList[partNumber] = {
                    ...state.itemsList[partNumber],
                    [uniqueIdentifier]: {
                        quantity: Number(currQuantity)
                    }
                };
            }
            state.cartID = cartID;
        },

        updateQuantity(state, action) {
            const { uniqueIdentifier, partNumber, type } = action.payload;
            const item = state.itemsList[partNumber];
            if (!item) return;

            const currentQty = item[uniqueIdentifier]?.quantity || 0;

            if (type === 'increase') {
                item.totalQuanity++;
                item[uniqueIdentifier] = { quantity: currentQty + 1 };
            } else if (type === 'decrease') {
                item.totalQuanity--;
                currentQty > 1
                    ? item[uniqueIdentifier] = { quantity: currentQty - 1 }
                    : delete item[uniqueIdentifier];

                if (item.totalQuanity === 0) {
                    delete state.itemsList[partNumber];
                }
            }
        },

        updateQuantityByPartNumber(state, action) {
            const { partNumber, type } = action.payload;
            const item = state.itemsList[partNumber];
            if (!item) return;

            // Try to find a sub-key that starts with "partNumber-"
            const subKey = Object.keys(item).find(key => key.startsWith(`${partNumber}-`));

            if (type === 'increase') {
                item.totalQuanity++;
                if (subKey) {
                    item[subKey].quantity++;
                }
            } else if (type === 'decrease') {
                item.totalQuanity--;
                if (item.totalQuanity <= 0) {
                    delete state.itemsList[partNumber];
                } else if (subKey) {
                    item[subKey].quantity--;
                    if (item[subKey].quantity <= 0) {
                        delete item[subKey];
                    }
                }
            }
        },

        deleteItem(state, action) {
            const { partNumber } = action.payload;
            delete state.itemsList[partNumber];
        },

        insertItemsToCart(state, action) {
            const { itemsList, cartID } = action.payload;
            state.itemsList = itemsList;
            state.cartID = cartID;
        },

        emptyItemsInCart(state) {
            state.itemsList = {};
        }
    }
});

export const globalCartSliceReducer = globalCartSliceSlice.reducer;
export const { addItemToCart, updateQuantity, deleteItem, insertItemsToCart, emptyItemsInCart, updateQuantityByPartNumber } = globalCartSliceSlice.actions;
