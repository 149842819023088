import { useState, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Chip, Input, Select, SelectItem, Textarea } from "@nextui-org/react";
import { useSelector } from 'react-redux';
import { RootState, useAgentTicketCreationMutation, useGetAdvisorsQuery } from '@circuitry-ai/doc-data';
import { checkType, CtyButton, CtyShowToast } from '@circuitry-ai/doc-ui';

/* eslint-disable-next-line */
export interface AgentCreationProps { }

export interface TicketCreationDetails {
  name?: string;
  description?: string;
  email?: string;
  advisor_id?: string;
}

//TODO: Error handling

export function AgentCreation(props: AgentCreationProps) {

  const { t } = useTranslation();
  const token = useSelector((state: RootState) => state.auth.token);
  const { details: user } = useSelector((state: RootState) => state.auth.user);
  const { data: advisorListData, error: advisorListError, isLoading: advisorListLoading, isFetching: advisorListFetching } = useGetAdvisorsQuery(undefined, { skip: !token, refetchOnMountOrArgChange: true });
  const { control, getValues, setValue, formState: { errors, isValid }, handleSubmit } = useForm<TicketCreationDetails>({
    mode: "onChange",
    defaultValues: {
      name: '',
      description: '',
      email: '',
      advisor_id: ''
    }
  });
  const [agentTicketCreation, { isLoading }] = useAgentTicketCreationMutation();
  const [responseMessage, setResponseMessage] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      setValue('email', user.email);
      setValue('name', user.name);
    }
  }, [user]);

  const handleSaveAnalyst = async () => {
    try {
      const data = getValues();
      const payLoad = {
        name: 'agent_ticket_creation',
        payload: {
          email: data.email,
          name: data.name,
          subject: 'About Service Advisor',
          description: data.description,
          ...(data.advisor_id && { advisor_id: data.advisor_id })
        }
      };
      const response = await agentTicketCreation(payLoad).unwrap();
      setResponseMessage(response?.message);
    } catch (e) {
      CtyShowToast(checkType.error, (e as Error)?.message ?? 'Something went wrong');
    }
  };

  return (
    <div className="flex flex-1 flex-col gap-3 lg:w-[60%] p-[12px] lg:p-[24px]">
      {responseMessage && <div className="bg-green-100 text-green-800 py-1 px-2 flex items-center w-full gap-2 text-[12px] rounded-[16px] border border-green-200">
        <Chip color={'success'} size="sm" variant="flat" className="bg-white border border-green-200">Success</Chip>
        {responseMessage}
      </div>}
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <div>
            <label className="flex items-center text-sm pb-1 font-medium">
              {t('Name')}
            </label>
            <Input {...field} size="md" readOnly />
          </div>
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <div>
            <label className="flex items-center text-sm pb-1 font-medium">
              {t('Email')}
            </label>
            <Input {...field} size="md" readOnly />
          </div>
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={{
          required: "Description is required",
          maxLength: { value: 130, message: "Description cannot exceed 130 characters" }
        }}
        render={({ field }) => (
          <div>
            <label className="flex items-center text-sm pb-1 font-medium">
              {t('Description')}<span className="text-red-500">*</span>
            </label>
            <Textarea {...field} size="lg" />
            {errors.description && <p className="text-red-500 text-xs">{errors.description.message}</p>}
          </div>
        )}
      />
      <Controller
        name="advisor_id"
        control={control}
        render={({ field }) => (
          <Select
            size="sm"
            label="Advisor ID"
            selectedKeys={[field.value ?? '']}
            disallowEmptySelection={true}
            onSelectionChange={(keys) => {
              const security = Array.from(keys)[0];
              field.onChange(security);
            }}
            aria-label="advisor-id"
            id="advisor-id"
            labelPlacement='outside'
            placeholder='select a advisor Id'
          >
            {advisorListData?.data.map((advisor: any) => (
              <SelectItem key={advisor?.advisor_id} value={advisor.advisor_id}>
                {advisor.name}
              </SelectItem>
            ))}
          </Select>
        )}
      />
      <div className='flex flex-row justify-end mt-2'>
        <CtyButton variant="solid" color="primary" size="md" radius="sm" isDisabled={!isValid} isLoading={isLoading} onPress={handleSubmit(handleSaveAnalyst)}>
          Create Ticket
        </CtyButton>
      </div>

    </div>

  );
}

export default AgentCreation;
