import { CtySpinner } from '@circuitry-ai/doc-ui';
import { Suspense, lazy } from 'react';

function lazyWithRetry(importFunction: () => Promise<any>) {
  const loadComponent = async () => {
    let retries = 3;

    const retry = async () => {
      try {
        return await importFunction();
      } catch (error) {
        if (retries > 0) {
          retries -= 1;
          // Add delay between retries (1 second)
          await new Promise(resolve => setTimeout(resolve, 1000));
          return retry();
        }
        throw error;
      }
    };

    return retry();
  };
  return lazy(loadComponent);


}

const FallbackComponent = () => <div className='flex items-center justify-center h-full'>{'Error Loading Component'}</div>;
const AdvisorListLazy = lazyWithRetry(() => import('@circuitry-ai/doc-viewadvisor').then(module => ({ default: module.AdvisorList }))
  .catch(error => {
    console.error(error);
    return { default: <FallbackComponent /> };
  }));

const DashboardLazy = lazyWithRetry(() => import('@circuitry-ai/doc-dashboard').then(module => ({ default: module.Dashboard }))
  .catch(error => {
    console.error(error);
    return { default: <FallbackComponent /> };
  }));

const SettingsLazy = lazyWithRetry(() => import('@circuitry-ai/doc-settings').then(module => ({ default: module.Settings }))
  .catch(error => {
    console.error(error);
    return { default: <FallbackComponent /> };
  }));
const ConversationHistoryLazy = lazyWithRetry(() => import('@circuitry-ai/doc-conversationhistory').then(module => ({ default: module.ConversationHistory }))
  .catch(error => {
    console.error(error);
    return { default: <FallbackComponent /> };
  }));
const ConversationMessagesLazy = lazyWithRetry(() => import('@circuitry-ai/doc-conversationhistory').then(module => ({ default: module.ConversationMessages }))
  .catch(error => {
    console.error(error);
    return { default: <FallbackComponent /> };
  }));
const KnowledgeLazy = lazyWithRetry(() => import('@circuitry-ai/doc-knowledge').then(module => ({ default: module.Knowledge }))
  .catch(error => {
    console.error(error);
    return { default: <FallbackComponent /> };
  }));
const AdvisorKnowledgeLazy = lazyWithRetry(() => import('@circuitry-ai/doc-knowledge').then(module => ({ default: module.Advisorknowledge }))
  .catch(error => {
    console.error(error);
    return { default: <FallbackComponent /> };
  }));
const AdvisorViewLazy = lazyWithRetry(() => import('@circuitry-ai/doc-viewadvisor').then(module => ({ default: module.Advisorview }))
  .catch(error => {
    console.error(error);
    return { default: <FallbackComponent /> };
  }));
const AnalyticsDetailsLazy = lazyWithRetry(() => import('@circuitry-ai/doc-analyst').then(module => ({ default: module.AnalyticsDetails }))
  .catch(error => {
    console.error(error);
    return { default: <FallbackComponent /> };
  }));
const AnalyticsListLazy = lazyWithRetry(() => import('@circuitry-ai/doc-analyst').then(module => ({ default: module.AnalyticsList }))
  .catch(error => {
    console.error(error);
    return { default: <FallbackComponent /> };
  }));
const AnalystBoardLazy = lazy(() => import('@circuitry-ai/doc-analyst').then(module => ({ default: module.AnalystBoard }))
  .catch(error => {
    console.error(error);
    return { default: () => <div>{'Error Loading Component'}</div> };
  }));
const AgentsLazy = lazyWithRetry(() => import('@circuitry-ai/doc-agent').then(module => ({ default: module.Agents }))
  .catch(error => {
    console.error(error);
    return { default: <FallbackComponent /> };
  }));
const AIWorkerListLazy = lazyWithRetry(() => import('@circuitry-ai/doc-aiworker').then(module => ({ default: module.AiworkersList }))
.catch(error => {
  console.error(error);
  return { default: <FallbackComponent /> };
}));
const AIWorkerViewLazy = lazyWithRetry(() => import('@circuitry-ai/doc-aiworker').then(module => ({ default: module.Aiworkers }))
.catch(error => {
  console.error(error);
  return { default: <FallbackComponent /> };
}));

const AdvisorList = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AdvisorListLazy {...props} />
  </Suspense>
);
const AdvisorView = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AdvisorViewLazy {...props} />
  </Suspense>
);
const Dashboard = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <DashboardLazy {...props} />
  </Suspense>
);
const Settings = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <SettingsLazy {...props} />
  </Suspense>
);
const ConversationHistory = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <ConversationHistoryLazy {...props} />
  </Suspense>
);
const ConversationMessages = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <ConversationMessagesLazy {...props} />
  </Suspense>
);
const Knowledge = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <KnowledgeLazy {...props} />
  </Suspense>
);
const Advisorknowledge = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AdvisorKnowledgeLazy {...props} />
  </Suspense>
);
const AnalyticsDetails = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AnalyticsDetailsLazy {...props} />
  </Suspense>
);
const AnalystBoard = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AnalystBoardLazy {...props} />
  </Suspense>
);
const AnalyticsList = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AnalyticsListLazy {...props} />
  </Suspense>
);

const Agents = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AgentsLazy {...props} />
  </Suspense>
);

const AIWorkerList = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AIWorkerListLazy {...props} />
  </Suspense>
);
const AIWorkerView = (props: any) => (
  <Suspense fallback={<CtySpinner size='lg' />}>
    <AIWorkerViewLazy {...props} />
  </Suspense>
);

export { AdvisorList, Dashboard, Settings, ConversationHistory, ConversationMessages, 
  Knowledge, Advisorknowledge, AdvisorView, AnalyticsDetails, AnalyticsList,
  Agents, AnalystBoard, AIWorkerList, AIWorkerView };