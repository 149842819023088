import { DragEvent, DragEventHandler, useImperativeHandle, forwardRef } from 'react';
import {
  ReactFlow,
  Background,
  ReactFlowProvider,
  ConnectionLineType,
  MarkerType,
  ConnectionMode,
  Panel,
  NodeTypes,
  DefaultEdgeOptions,
  Controls,
  useReactFlow,
  MiniMap,
  Edge,
  Node,
  useNodesState,
  useEdgesState,
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';
import './floweditorStyles.css';
import ShapeNodeComponent from './components/shape-node';
import MiniMapNode from './components/minimap-node';
import { ShapeNode, ShapeType } from './components/shape/types';

import useLayout from './components/hooks/useLayout';
import { nodeTypes as wfnodes } from './components/node-types';
import edgeTypes from './components/edge-types';
import NodeAttributesPanel from './components/node-attributes';

const nodeTypes: NodeTypes = {
  shape: ShapeNodeComponent,
  ...wfnodes,
};

const defaultEdgeOptions: DefaultEdgeOptions = {
  type: 'smoothstep',
  markerEnd: { type: MarkerType.ArrowClosed },
  style: { strokeWidth: 2 },
};

const proOptions = { account: 'paid-pro', hideAttribution: true };

export type FlowData = {
  nodes: Node[];
  edges: Edge[];
  main_input: string;
  circuit_name: string;
};

type FlowEditorProps = Readonly<{
  theme?: 'dark' | 'light';
  snapToGrid?: boolean;
  panOnScroll?: boolean;
  zoomOnDoubleClick?: boolean;
  showMinimap?: boolean;
  defaultNodes?: ShapeNode[];
  defaultEdges?: Edge[];
  onSave?: (flowData: FlowData) => void;
  initialData?: FlowData;
}>;

export interface FlowEditorRef {
  getFlowData: () => FlowData;
}

function FlowEditor({
  theme = 'light',
  snapToGrid = true,
  panOnScroll = true,
  zoomOnDoubleClick = false,
  showMinimap = true,
  defaultNodes = [],
  defaultEdges = [],
  onSave,
  initialData,
}: FlowEditorProps, ref: React.Ref<FlowEditorRef>) {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialData?.nodes || defaultNodes);
  const [edges, , onEdgesChange] = useEdgesState(initialData?.edges || defaultEdges);
  const { screenToFlowPosition } = useReactFlow<ShapeNode>();

  useImperativeHandle(ref, () => ({
    getFlowData: () => ({ nodes, edges })
  }));

  // this hook call ensures that the layout is re-calculated every time the graph changes
  useLayout();

  const onDragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = 'move';
  };

  // this function is called when a node from the sidebar is dropped onto the react flow pane
  const onDrop: DragEventHandler = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    const type = evt.dataTransfer.getData('application/reactflow') as ShapeType;

    // this will convert the pixel position of the node to the react flow coordinate system
    // so that a node is added at the correct position even when viewport is translated and/or zoomed in
    const position = screenToFlowPosition({ x: evt.clientX, y: evt.clientY });

    const newNode: ShapeNode = {
      id: Date.now().toString(),
      type: 'shape',
      position,
      style: { width: 100, height: 100 },
      data: {
        type,
        color: '#3F8AE2',
      },
      selected: true,
    };

    setNodes((nodes) =>
      (nodes.map((n) => ({ ...n, selected: false })) as ShapeNode[]).concat([
        newNode,
      ])
    );
  };

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      colorMode={theme}
      proOptions={proOptions}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      defaultEdgeOptions={defaultEdgeOptions}
      connectionLineType={ConnectionLineType.SmoothStep}
      fitView
      connectionMode={ConnectionMode.Loose}
      panOnScroll={panOnScroll}
      onDrop={onDrop}
      snapToGrid={snapToGrid}
      snapGrid={[10, 10]}
      onDragOver={onDragOver}
      zoomOnDoubleClick={zoomOnDoubleClick}
    >
      <Background />
      <Panel position="top-right">
        <NodeAttributesPanel />
      </Panel>
      <Controls position='top-left'/>
      {showMinimap && <MiniMap zoomable draggable nodeComponent={MiniMapNode} position='bottom-left' />}
    </ReactFlow>
  );
}

const ForwardedFlowEditor = forwardRef(FlowEditor);

export const FlowEditorWrapper = forwardRef<FlowEditorRef, {
  initialData?: FlowData;
  showMinimap?: boolean;
}>(({ initialData, showMinimap }, ref) => {
  const defaultProps: FlowEditorProps = {
    theme: 'light',
    snapToGrid: true,
    panOnScroll: true,
    zoomOnDoubleClick: false,
    showMinimap: false,
    defaultNodes: [
      {
        id: '1',
        type: 'shape',
        position: { x: 0, y: 0 },
        style: { width: 160, height: 40 },
        data: {
          type: 'rectangle',
        },
      },
      {
        id: '2',
        data: { label: '+', type: 'plus' },
        position: { x: 0, y: 100 },
        type: 'placeholder',
      },
    ],
    defaultEdges: [
      {
        id: '1=>2',
        source: '1',
        target: '2',
        type: 'placeholder',
      },
    ],
  };

  const mergedProps = {
    ...defaultProps,
    initialData,
    showMinimap,
  };

  return (
    <ReactFlowProvider>
      <ForwardedFlowEditor {...mergedProps} ref={ref} />
    </ReactFlowProvider>
  );
});

export default FlowEditorWrapper;
