import React, { useState, useEffect } from 'react';
import { useGetPartsZipComponentDetailsMutation, useGetPdfLinkForConversationsMutation } from "@circuitry-ai/doc-data";
import { PartsSvgViewer } from "./partssvgviewer";
import { Icon } from '@iconify/react';
import CtySpinner from '../spinner/CtySpinner';
import { ResizableBox } from 'react-resizable';
import type { ResizeCallbackData } from 'react-resizable';
import 'react-resizable/css/styles.css';

export interface ComponentNode {
    name: string;
    path: string;
}

export interface ComponentGroup {
    component_name: string;
    components: ComponentNode[];
}

export interface ComponentDetails {
    component_name: string;
    referenceNumber: string;
    parts_number: string;
    name: string;
    quantity: string;
}

export interface ZipExplorerProps {
    hierarchyData: ComponentGroup[];
    svgUrl: string;
    sourceIdData: any;
    advisorId?: string;
}

// Helper function to determine file type
const getFileType = (fileName: string): 'pdf' | 'svg' | 'image' | 'other' => {
    if (!fileName) return 'other';
    const extension = fileName.toLowerCase().split('.').pop() || '';
    if (extension === 'pdf') return 'pdf';
    if (extension === 'svg') return 'svg';
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) return 'image';
    return 'other';
};

function ComponentDetailsPanel({ details, onClose, setActiveComponentDetails, activeComponentDetails }: {
    details?: [ComponentDetails];
    onClose: () => void,
    setActiveComponentDetails: (detail: ComponentDetails) => void,
    activeComponentDetails: ComponentDetails | null
}) {
    if (!details) return null;

    return (
        <div className="h-full bg-white flex flex-col">
            {/* Fixed Header */}
            <div className="flex-none px-4 py-4 border-b border-gray-200">
                <div className="flex justify-between items-center">
                    <h2 className="font-bold">Component Details</h2>
                    <button onClick={onClose} className="p-1" title='Collapse'>
                        <Icon icon="lets-icons:expand-right" width="24" height="24" style={{ color: "#000" }} />
                    </button>
                </div>
            </div>

            {/* Scrollable Content */}
            <div className="flex-1 overflow-y-auto overflow-x-auto custom-scrollbar">
                <div className="p-4 min-w-[250px]">
                    <table className="w-full border-collapse">
                        <thead className="sticky top-0 bg-[#f8f9fa] z-10">
                            <tr>
                                <th className="border border-gray-200 p-2 text-left">Ref</th>
                                <th className="border border-gray-200 p-2 text-left">Part Number</th>
                                <th className="border border-gray-200 p-2 text-left">Part Name</th>
                                <th className="border border-gray-200 p-2 text-left">Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details.map((detail) => (
                                <tr
                                    key={detail.referenceNumber}
                                    onClick={() => setActiveComponentDetails(detail)}
                                    className={`
                                        cursor-pointer hover:bg-gray-50
                                        ${activeComponentDetails?.referenceNumber === detail?.referenceNumber ? 'bg-blue-50' : ''}
                                    `}
                                >
                                    <td className="border border-gray-200 p-2">{detail.referenceNumber}</td>
                                    <td className="border border-gray-200 p-2">{detail.parts_number}</td>
                                    <td className="border border-gray-200 p-2">{detail.name}</td>
                                    <td className="border border-gray-200 p-2">{detail.quantity}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

const ResizablePanel: React.FC<{
    width: number;
    onResize: (width: number) => void;
    minWidth?: number;
    maxWidth?: number;
    children: React.ReactNode;
    handlePosition?: 'e' | 'w';
    className?: string;
}> = ({ width, onResize, minWidth = 150, maxWidth = 400, children, handlePosition = 'e', className = '' }) => {
    const handleResize = (e: React.SyntheticEvent, { size }: ResizeCallbackData) => {
        onResize(size.width);
    };

    return (
        <ResizableBox
            width={width}
            height={Infinity}
            minConstraints={[minWidth, Infinity]}
            maxConstraints={[maxWidth, Infinity]}
            onResize={handleResize}
            resizeHandles={[handlePosition]}
            className={`flex-shrink-0 ${className}`}
            axis="x"
        >
            {children}
        </ResizableBox>
    );
};

function ZipFileExplorer({
    hierarchyData,
    svgUrl,
    sourceIdData,
    advisorId,
}: ZipExplorerProps) {
    // This state holds the currently active file's information
    const [activeFile, setActiveFile] = useState<{
        url: string;
        type: 'pdf' | 'svg' | 'image' | 'other';
        name: string;
        details?: [ComponentDetails];
    }>({
        url: svgUrl,
        type: getFileType(svgUrl),
        name: ''
    });

    // Left panel open/close state.
    const [leftPanelOpen, setLeftPanelOpen] = useState(true);
    const [rightPanelOpen, setRightPanelOpen] = useState(true);
    const [getPdfLink] = useGetPdfLinkForConversationsMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [getZipComponents] = useGetPartsZipComponentDetailsMutation();
    const [leftPanelWidth, setLeftPanelWidth] = useState(200);
    const [rightPanelWidth, setRightPanelWidth] = useState(200);
    const [activeComponentDetails, setActiveComponentDetails] = useState<ComponentDetails | null>(null);

    useEffect(() => {
        if (hierarchyData?.length > 0 && hierarchyData[0]?.components?.length > 0) {
            const sortedData = [...hierarchyData].sort((a, b) =>
                a.component_name.localeCompare(b.component_name)
            );
            const firstGroup = sortedData[0];
            const firstComponent = firstGroup.components[1];
            if (firstComponent.path) {
                handleFileOpen(
                    firstGroup.component_name,
                    firstComponent.path,
                    firstComponent.name
                );

            }
        }
    }, [hierarchyData]);
    // Called when a file node is clicked.
    const handleFileOpen = async (groupName: string, path: string, componentName: string) => {
        if (!path) return;

        setIsLoading(true);
        const fileType = getFileType(path);

        try {

            const zipComponents = await getZipComponents({ source_id: sourceIdData, componentGroup: groupName, componentName, filename: path.split('/').pop() ?? '' }).unwrap();
            setActiveFile({
                url: zipComponents?.presigned_url || '',
                type: fileType,
                name: componentName,
                details: zipComponents?.component_details || []
            });
            // setRightPanelOpen(true);
        } catch (error) {
            console.error("Error handling file:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Render the content based on file type
    const renderContent = () => {
        if (isLoading) {
            return (
                <div className="flex items-center justify-center h-full">
                    <CtySpinner size="lg" />
                </div>
            );
        }

        switch (activeFile.type) {
            case 'svg':
                return <PartsSvgViewer svgUrl={activeFile?.url ?? ''} isFileViewer={true} currentActivePartsRowData={[activeComponentDetails]} setSelectedRowId={() => { }} activeSourceData={[]} filename={activeFile.name} partsListFromFileViewer={activeFile?.details || []} />;
            case 'pdf':
            case 'image':
                return (
                    <div className="w-full h-full">
                        <div className="px-3 py-2 font-medium text-base text-black">
                            {activeFile?.name || ''}
                        </div>
                        <iframe
                            src={activeFile?.url}
                            className="w-full h-[calc(100%-40px)]"
                            title={activeFile?.name}
                        />
                    </div>
                );
            default:
                return (
                    <div className="flex items-center justify-center h-full text-gray-500">
                        <CtySpinner size="lg" />
                    </div>
                );
        }
    };

    return (
        <div className="flex flex-row h-screen overflow-hidden">
            {/* Left Panel: Component structure */}
            {leftPanelOpen ? (
                <ResizablePanel
                    width={leftPanelWidth}
                    onResize={(width) => setLeftPanelWidth(width)}
                    className="border-r border-gray-200"
                >
                    <div className="flex flex-col h-full">
                        <div className="flex-none px-2 py-4 border-b border-gray-200">
                            <div className="flex items-center justify-between">
                                <h2 className="font-bold">Components</h2>
                                <button onClick={() => setLeftPanelOpen(false)} title="Collapse">
                                    <Icon icon="lets-icons:expand-left" width="24" height="24" style={{ color: "#000" }} />
                                </button>
                            </div>
                        </div>
                        <div className="flex-1 overflow-y-auto overflow-x-auto custom-scrollbar">
                            <ComponentList
                                data={hierarchyData}
                                onComponentClick={handleFileOpen}
                                activeFileName={activeFile.name}
                            />
                        </div>
                    </div>
                </ResizablePanel>
            ) : (
                <div className="w-8 flex-shrink-0 flex items-start justify-center border-r border-gray-200">
                    <button onClick={() => setLeftPanelOpen(true)} className="p-1" title="Expand">
                        <Icon icon="solar:hamburger-menu-outline" width="24" height="24" style={{ color: "#000" }} />
                    </button>
                </div>
            )}

            {/* Main Content Area */}
            <div className="flex-1 overflow-hidden max-h-[100vh]">
                <div className="h-full overflow-y-hidden overflow-x-auto custom-scrollbar">
                    {renderContent()}
                </div>
            </div>

            {/* Right Panel */}
            {activeFile.details && activeFile.details.length > 0 && (
                rightPanelOpen ? (
                    <ResizablePanel
                        width={rightPanelWidth}
                        onResize={(width) => setRightPanelWidth(width)}
                        handlePosition="w"
                        className="border-l border-gray-200"
                    >
                        <ComponentDetailsPanel
                            details={activeFile.details}
                            onClose={() => setRightPanelOpen(false)}
                            setActiveComponentDetails={setActiveComponentDetails}
                            activeComponentDetails={activeComponentDetails}
                        />
                    </ResizablePanel>
                ) : (
                    <div className="w-8 flex-shrink-0 flex items-start justify-center border-l border-gray-200">
                        <button onClick={() => setRightPanelOpen(true)} className="p-1" title="Expand">
                            <Icon icon="solar:hamburger-menu-outline" width="24" height="24" style={{ color: "#000" }} />
                        </button>
                    </div>
                )
            )}
        </div>
    );
}

// The ComponentList recursively renders the component structure
interface ComponentListProps {
    data: ComponentGroup[];
    onComponentClick: (groupName: string, path: string, name: string) => void;
    activeFileName: string;
}

function ComponentList({ data, onComponentClick, activeFileName }: ComponentListProps) {
    const sortedData = [...data].sort((a, b) =>
        a.component_name.localeCompare(b.component_name)
    );

    return (
        <ul className="flex flex-col gap-3">
            {sortedData.map((group) => (
                <ComponentGroupItem
                    key={group.component_name}
                    group={group}
                    onComponentClick={onComponentClick}
                    activeFileName={activeFileName}
                />
            ))}
        </ul>
    );
}

interface ComponentGroupItemProps {
    group: ComponentGroup;
    onComponentClick: (groupName: string, path: string, name: string) => void;
    activeFileName: string;
}

function ComponentGroupItem({ group, onComponentClick, activeFileName }: ComponentGroupItemProps) {
    const [expanded, setExpanded] = useState(true);

    return (
        <li>
            <div className="flex items-center justify-between pl-3">
                <span className="font-bold">{group.component_name}</span>
                <button onClick={() => setExpanded(!expanded)} className="mr-2" title={expanded ? "Collapse" : "Expand"}>
                    {expanded ? (
                        <Icon icon="lets-icons:expand-up" width="24" height="24" style={{ color: "#000" }} xlinkTitle='expand' />
                    ) : (
                        <Icon icon="lets-icons:expand-down" width="24" height="24" style={{ color: "#000" }} />
                    )}
                </button>
            </div>
            {expanded && (
                <div className="ml-4">
                    <ul className="flex flex-col gap-2 mt-2">
                        {group.components.map((component) => (
                            <button
                                key={component.name}
                                className={`mx-2 py-2 flex justify-start cursor-pointer ${component.path ? 'text-blue-600 hover:underline' : 'text-gray-400'
                                    } ${component.name === activeFileName ? 'font-bold' : ''}`}
                                onClick={() => component.path && onComponentClick(group.component_name, component.path, component.name)}
                            >
                                <span className="ml-2">
                                    {component.name}
                                </span>
                            </button>
                        ))}
                    </ul>
                </div>
            )}
        </li>
    );
}

export default ZipFileExplorer;