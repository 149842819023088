import apiEndPoints from "../../apiconstants";
import { api, handleErrorResponse, responseArgs } from "../api";
import { AIWorker } from "./aiworkerTypes";

export enum categoryType {
    ANALYST = 'analyst',
    SPECIALIST = 'specialist',
}

export const aidesApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getAiWorkersByCategory: builder.query<AIWorker[], categoryType>({
            query: (payload) => `${apiEndPoints?.getAiWorkersByCategory}?category=${payload}`,
            transformResponse: (response: responseArgs) => response.data as AIWorker[],
            transformErrorResponse: handleErrorResponse,
        }),
        getAiWorkerById: builder.query<AIWorker, string>({
            query: (workerId) => `${apiEndPoints?.getAiWorkerById}/${workerId}`,
            transformResponse: (response: responseArgs) => response.data as AIWorker,
            transformErrorResponse: handleErrorResponse,
        }),
        fetchAiWorkersByCategory: builder.mutation<any, categoryType>({
            query: (payload) => ({
                url: `${apiEndPoints?.getAiWorkersByCategory}?category=${payload}`,
                method: 'GET'
            }),
            transformResponse: (response: responseArgs) => response.data as Report[],
            transformErrorResponse: handleErrorResponse,
        }),
        updateAiWorkers: builder.mutation<any, any>({
            query: ({ payLoad, aiworker_id }) => ({
                url: `${apiEndPoints?.updateAiWorkers}/${aiworker_id}`,
                method: 'PATCH',
                body: payLoad
            }),
            transformErrorResponse: handleErrorResponse,
        }),
        createAiWorkers: builder.mutation<any, any>({
            query: (payLoad) => ({
                url: `${apiEndPoints?.createAiWorkers}`,
                method: 'POST',
                body: payLoad
            }),
            transformErrorResponse: handleErrorResponse,
        }),
        deleteAiWorkers: builder.mutation<any, string>({
            query: (aiworker_id) => ({
                url: `${apiEndPoints?.deleteAiWorkers}/${aiworker_id}`,
                method: 'DELETE'
            }),
            transformErrorResponse: handleErrorResponse,
            transformResponse: (response: responseArgs) => response.data
        }),
        circuitConnector: builder.mutation<any, any>({
            query: (payLoad) => ({
                url: `${apiEndPoints?.circuitConnector}`,
                method: 'POST',
                body: payLoad
            }),
            transformErrorResponse: handleErrorResponse,
            transformResponse: (response: responseArgs) => response.data
        }),
    })
});

export const { useGetAiWorkersByCategoryQuery, useFetchAiWorkersByCategoryMutation,
    useUpdateAiWorkersMutation, useCreateAiWorkersMutation,
    useGetAiWorkerByIdQuery, useDeleteAiWorkersMutation, useCircuitConnectorMutation } = aidesApiSlice;