import { Icon } from "@iconify/react";
import { CtyButton } from "../button/CtyButton";
import { Badge, Button, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react';
import { RootState, useAppDispatch, deleteItem, ItemDetailsState, useGetCartItemsQuery, insertItemsToCart, emptyItemsInCart, useUpdateCartItemMutation, useDeleteCartItemMutation, useDeleteItemInCartMutation, useGetCartCsvFileMutation, usePlaceCartOrderMutation, updateQuantityByPartNumber } from "@circuitry-ai/doc-data";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { checkType, CtyShowToast } from "../toast/CtyToast";

interface CartItemsListProps {
    openCart?: boolean;
    popupPlacement?: any;
}

enum CartDownloadType {
    CSV = 'csv',
    JSON = 'json',
}

const CartItemsList = (props: CartItemsListProps) => {

    const { openCart = false, popupPlacement = 'bottom' } = props;
    const { itemsList, cartID } = useSelector((state: RootState) => state.globalCartSliceReducer) as { [key: string]: ItemDetailsState; };

    const dispatch = useAppDispatch();
    const token = useSelector((state: RootState) => state.auth.token);
    const [skip, setSkip] = useState<boolean>(true);
    const [isCartOpen, setIsCartOpen] = useState<boolean>(openCart ?? false);
    const [placeOrder, setPlaceOrder] = useState<boolean>(false);

    const [updateCartItem] = useUpdateCartItemMutation();
    const [deleteCartItem] = useDeleteCartItemMutation();
    const [deleteItemInCart] = useDeleteItemInCartMutation();

    const { data: cartItemsList, refetch } = useGetCartItemsQuery(undefined, { refetchOnMountOrArgChange: true, skip });
    const [getCartCsvFile, { isLoading: isfetchingCartCsvFile }] = useGetCartCsvFileMutation();
    const [placeCartOrder, { isLoading: isPlacingOrder }] = usePlaceCartOrderMutation();

    useEffect(() => {
        if (token) {
            setSkip(false);
        } else {
            setSkip(true);
        }
    }, [token]);

    useEffect(() => {
        if (openCart) {
            setIsCartOpen(openCart);
        }
    }, [openCart]);

    useEffect(() => {
        if (cartItemsList?.[0]?.items?.length > 0) {
            const itemsList = cartItemsList?.[0]?.items.reduce((acc: any, item: any) => {
                acc[item.code] = {
                    totalQuanity: item.quantity,
                    inCart: true,
                    id: item.id,
                    partNumber: item.code,
                    partName: item.name,
                };
                return acc;
            }, {});
            dispatch(insertItemsToCart({ itemsList, cartID: cartItemsList?.[0]?.cart_id }));
        }
    }, [cartItemsList]);

    const incrementQuantity = async (item: ItemDetailsState) => {
        try {
            dispatch(updateQuantityByPartNumber({ partNumber: item.partNumber, type: 'increase' }));

            const payLoad = {
                cart_id: cartID,
                name: item.partName,
                code: item.partNumber,
                type: 'part',
                price: 0,
            };

            await updateCartItem(payLoad);

        } catch (e) {
            CtyShowToast(checkType.error, (e as Error)?.message || 'Operation failed');
        }
    };

    const decrementQuantity = async (item: ItemDetailsState) => {
        try {
            dispatch(updateQuantityByPartNumber({ partNumber: item.partNumber, type: 'decrease' }));
            const payLoad = {
                code: item.partNumber,
                cartID,
            };
            await deleteCartItem(payLoad);

        } catch (e) {
            CtyShowToast(checkType.error, (e as Error)?.message || 'Operation failed');
        }
    };

    const handleDelete = async (item: ItemDetailsState) => {
        try {
            dispatch(deleteItem({ partNumber: item.partNumber }));
            const payLoad = {
                itemID: item.id,
            };
            await deleteItemInCart(payLoad);
        } catch (e) {
            CtyShowToast(checkType.error, (e as Error)?.message || 'Operation failed');
        }
    };

    const handleDownload = async (type: CartDownloadType) => {
        try {
            let response;
            if (type === CartDownloadType.CSV) {
                response = await getCartCsvFile(cartID);
                window.open(response.data, '_blank');
                dispatch(emptyItemsInCart());
            } else {
                response = await placeCartOrder(cartID);
                const fileName = 'data.json';
                const jsonStr = JSON.stringify(response, null, 2);
                const blob = new Blob([jsonStr], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(url);
            }
        } catch (e) {
            CtyShowToast(checkType.error, (e as Error)?.message || 'Operation failed');
        } finally {
            setPlaceOrder(false);
            refetch();
        }
    };

    // Pre-calculate item count and convert itemsList into an array for mapping
    const itemCount = useMemo(() => Object.keys(itemsList).length, [itemsList]);
    const itemsArray = useMemo(() => Object.entries(itemsList), [itemsList]);

    return (
        <Popover placement={popupPlacement} isOpen={isCartOpen} onOpenChange={(isOpen: boolean) => setIsCartOpen(isOpen)}>
            <Badge
                color="danger"
                content={itemCount}
                size="md"
                isInvisible={itemCount === 0}
            >
                <PopoverTrigger>
                    <Button id="cart-list-btn" isIconOnly radius="sm" size="sm" variant="light">
                        <Icon icon="bytesize:cart" width="1.4rem" height="1.4rem" style={{ color: "#000" }} />
                    </Button>
                </PopoverTrigger>
            </Badge>
            <PopoverContent className="bg-white w-[300px]">
                <div className="flex flex-col w-full">
                    <div className="p-3 text-lg font-semibold border-b">My Cart</div>
                    <div aria-label="carts-list" className={`w-full ${itemCount > 3 ? "max-h-[300px] overflow-y-auto" : ""}`}>
                        {itemsArray.map(([key, item]) => (
                            <div key={key} className="p-0 m-0 border-b">
                                <div className="flex flex-row justify-between items-start p-2 ">
                                    <div className="flex-1 flex flex-col gap-1">
                                        <div>{item.partName}</div>
                                        <div>Code: <span className="font-light text-xs">{item.partNumber}</span></div>
                                        <div className="flex items-center gap-1">
                                            <CtyButton isIconOnly size="sm" variant="light" onPress={() => decrementQuantity(item)}>
                                                <Icon icon="tabler:minus" width="16" height="16" style={{ color: "#004493" }} />
                                            </CtyButton>
                                            <span className="text-blue-700 font-semibold w-[20px] bg-[#F5F5F5] flex justify-center">
                                                {item.totalQuanity}
                                            </span>
                                            <CtyButton isIconOnly size="sm" variant="light" onPress={() => incrementQuantity(item)}>
                                                <Icon icon="tabler:plus" width="16" height="16" style={{ color: "#004493" }} />
                                            </CtyButton>
                                        </div>
                                    </div>
                                    <Button
                                        id={`${key}-delete-btn`}
                                        isIconOnly
                                        radius="sm"
                                        size="sm"
                                        variant="light"
                                        onPress={() => handleDelete(item)}
                                    >
                                        <Icon icon="hugeicons:delete-02" width="1.1rem" height="1.1rem" />
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                    {!placeOrder &&
                        <CtyButton className="my-2" variant="bordered" radius="sm" size="lg" onPress={() => setPlaceOrder(true)} isDisabled={itemCount === 0}>
                            Place Order
                        </CtyButton>
                    }
                    {placeOrder &&
                        <>
                            <CtyButton className="my-2" isLoading={isfetchingCartCsvFile} variant="bordered" radius="sm" size="lg" onPress={() => handleDownload(CartDownloadType.CSV)}>
                                Download CSV
                            </CtyButton>
                            <CtyButton className="my-2" isLoading={isPlacingOrder} variant="bordered" color={'primary'} radius="sm" size="lg" onPress={() => handleDownload(CartDownloadType.JSON)}>
                                Download JSON
                            </CtyButton>
                        </>
                    }
                </div>
            </PopoverContent>
        </Popover>
    );
};


export { CartItemsList };