import { useCallback } from 'react';
import { useReactFlow, useStore } from '@xyflow/react';
import styles from './styles.module.css';
import { Select, SelectItem } from '@nextui-org/react';

const NodeAttributesPanel = () => {
  const { setNodes } = useReactFlow();
  const { nodes } = useStore((state) => state);
  const selectedNode = useStore(useCallback((state) =>
    state.nodes.find((node) => node.selected)
    , []));

  if (!selectedNode) {
    return (
      <div className={styles.panel}>
        <h4>Properties</h4>
        <p className={styles.empty}>Select a node to edit properties</p>
      </div>
    );
  }

  const updateNodeData = (key: string, value: any) => {
    setNodes(nodes =>
      nodes.map(node => {
        if (node.id !== selectedNode.id) return node;

        let newData = { ...node.data };

        if (key === 'label') {
          newData = { ...newData, role: value, label: value };
        } else if (key === 'variables') {
          const varKey = selectedNode.data.tool === 'SNSTool' ? 'sns_arn' : 'bucket_name';
          newData = { ...newData, variables: { [varKey]: value }, variable: value };
        } else {
          newData = { ...newData, [key]: value };
        }
        return { ...node, data: newData };
      })
    );
  };

  const firstNode = nodes[0].id === selectedNode.id;


  return (

    <div className={styles.panel}>
      {firstNode &&
        <>
          <div className='text-medium pb-1'>Main Input</div>
          <div className={styles.field}>
            <input
              id="main-input"
              type="text"
              value={typeof selectedNode?.data?.main_input === 'string' ? selectedNode?.data?.main_input : ''}
              onChange={e => updateNodeData('main_input', e.target.value)}
              placeholder="Enter main input"
            />
          </div>
        </>
      }
      <h4>Properties</h4>
      <div className={styles.field}>
        <label htmlFor="node-label">Role</label>
        <input
          id="node-label"
          type="text"
          value={typeof selectedNode.data.label === 'string' ? selectedNode.data.label : ''}
          onChange={e => updateNodeData('label', e.target.value)}
          placeholder="Enter role"
        />
      </div>
      <div className={styles.field}>
        <label htmlFor="node-Id">Id</label>
        <input
          id="node-Id"
          type="text"
          value={typeof selectedNode?.data?.id === 'string' ? selectedNode?.data?.id : ''}
          onChange={e => updateNodeData('id', e.target.value)}
          placeholder="Enter id"
        />
      </div>
      <div className={styles.field}>
        <label htmlFor="node-goal">Goal</label>
        <input
          id="node-goal"
          type="text"
          value={typeof selectedNode?.data?.goal === 'string' ? selectedNode?.data?.goal : ''}
          onChange={e => updateNodeData('goal', e.target.value)}
          placeholder="Enter goal"
        />
      </div>
      <div className={styles.field}>
        <label htmlFor="node-tool">Tools</label>
        <Select
          size="sm"
          placeholder="Select tool"
          selectedKeys={new Set([selectedNode?.data?.tool ?? ''])}
          disallowEmptySelection={true}
          onSelectionChange={(keys) => {
            const security = Array.from(keys)[0];
            updateNodeData('tool', security);
          }}
          aria-label="tool-data"
          id="node-tool"
        >
          {['SNSTool', 'S3ReaderTool'].map((skill) => (
            <SelectItem key={skill} value={skill}>
              {skill}
            </SelectItem>
          ))}
        </Select>
      </div>
      {(selectedNode?.data?.tool ?? false) && <div className={styles.field}>
        <label htmlFor="node-attr">{selectedNode?.data?.tool === 'SNSTool' ? 'SNS ARN' : 'Bucket name'}</label>
        <input
          id="node-attr"
          type="text"
          value={typeof selectedNode?.data?.variable === 'string' ? selectedNode?.data?.variable : ''}
          onChange={e => updateNodeData("variables", e.target.value)}
          placeholder="Enter value"
        />
      </div>}
      <div className={styles.field}>
        <label htmlFor="node-color">Color</label>
        <input
          id="node-color"
          type="color"
          value={typeof selectedNode.data.color === 'string' ? selectedNode.data.color : '#ffffff'}
          onChange={e => updateNodeData('color', e.target.value)}
        />
      </div>
    </div>

  );
};

export default NodeAttributesPanel;
