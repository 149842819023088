import apiEndPoints from "../../apiconstants";
import { api, handleErrorResponse, responseArgs } from "../api";

export const agentApiSlice = api.injectEndpoints({
    endpoints: builder => ({
        getPDFUploadPresignedUrl: builder.mutation<string, any>({
            query: (payLoad) => ({
                url: apiEndPoints.getPDFPresignedURL,
                method: 'POST',
                body: payLoad
            }),
            transformResponse: (response: responseArgs) => response.data?.[0],
            transformErrorResponse: handleErrorResponse,
        }),
        getAgentsJSON: builder.mutation<any, any>({
            query: (payLoad) => ({
                url: apiEndPoints.getAgentsJSON,
                method: 'POST',
                body: payLoad
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: handleErrorResponse
        }),
        repairProfile: builder.mutation<any, any>({
            queryFn: async (payLoad: any) => {
                const response = await fetch(`https://uf1mhp7sjh.execute-api.us-east-1.amazonaws.com/dev/defect`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'wMUG1SZN8B1yUs8Y0odNL9PJOCvOa7Dx7o1ytBaT'
                    },
                    mode: 'cors',
                    body: JSON.stringify(payLoad)
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }

                const data = await response.json();
                return { data: data };
            }
        }),
        getClaimScore: builder.mutation<any, any>({
            queryFn: async (payLoad: any) => {
                const response = await fetch(`https://teomtdklca.execute-api.us-east-1.amazonaws.com/dev/claim_score_prediction`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    mode: 'cors',
                    body: JSON.stringify(payLoad)
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }
                const data = await response.json();
                return { data: data };
            }
        }),
        generatePresignedUrl: builder.mutation<{ presigned_url: string, unique_id: string; }, { file_name: string; }>({
            query: (payLoad) => ({
                url: apiEndPoints.getPresignedURl,
                method: 'POST',
                body: payLoad
            }),
            transformResponse: (response: responseArgs) => response.data,
            transformErrorResponse: handleErrorResponse,
        }),
        getClaimScoreTableData: builder.mutation<string, any>({
            query: (payLoad) => ({
                url: apiEndPoints.getClaimsScore,
                method: 'POST',
                body: payLoad
            }),
            transformResponse: (response: responseArgs) => response.data,
            transformErrorResponse: handleErrorResponse,
        }),
        uploadAgentImage: builder.mutation<string, any>({
            query: (payLoad) => ({
                url: apiEndPoints.uploadAgentImage,
                method: 'POST',
                body: payLoad
            }),
            transformResponse: (response: responseArgs) => response.data,
            transformErrorResponse: handleErrorResponse,
        }),
        getImageModalData: builder.mutation<string, any>({
            query: (payLoad) => ({
                url: apiEndPoints.getImageModalData,
                method: 'POST',
                body: payLoad
            }),
            transformErrorResponse: handleErrorResponse,
            transformResponse: (response: responseArgs) => response.data,
        }),
        agentTicketCreation: builder.mutation<any, any>({
            query: (payLoad) => ({
                url: apiEndPoints.agentTicketCreation,
                method: 'POST',
                body: payLoad
            }),
            transformResponse: (response: responseArgs) => response.data,
            transformErrorResponse: handleErrorResponse,
        }),
    })
});

export const { useGetPDFUploadPresignedUrlMutation, useGetAgentsJSONMutation, useRepairProfileMutation, useGetClaimScoreMutation, useGeneratePresignedUrlMutation, useGetClaimScoreTableDataMutation, useUploadAgentImageMutation, useGetImageModalDataMutation, useAgentTicketCreationMutation } = agentApiSlice;