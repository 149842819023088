"use client";
import React, { useEffect, useRef, useState } from 'react';
import type { TextAreaProps } from "@nextui-org/react";
import { Spinner, Textarea, Input, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { cn } from "./cn";
import { ID_CONSTANTS, promptInputType } from '../utils/constants';
import { CtyButton } from '../button/CtyButton';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { Advisor, RootState, updateCurrentChatMentionedEntities, useAppDispatch } from '@circuitry-ai/doc-data';
import { SearchableDropdown } from '../utiluicomponents/searchabledropdown';
import { SpeechUtility } from '../utils/speechUtils';
import { generateCircuitryLogoSvgBlobUrl } from '../utils/commonutils';
import MicrophoneWave from '../utiluicomponents/Microphonewave';


interface CtyPromptInputProps extends TextAreaProps {
  inputHeight?: string;
  enableTranslation?: boolean;
  enableSpeak?: boolean;
  enableImage?: boolean;
  isSpeechMode?: boolean;
  enableSendBtn?: boolean;
  enableSearch?: boolean;
  setIsSpeechMode?: (value: boolean) => void;
  handleSendClick: (userInput: string) => void;
  triggerEveryInputChange?: boolean;
  elementType: promptInputType;
  handleImageUpload?: (key: React.Key, e: any) => void;
  enableMention?: boolean;
  enableLiveChat?: boolean;
  clearInputOnSend?: boolean
  isSearchSpinnerLoading?: boolean;
}
interface MentionAdvisor {
  advisor_id: string;
  name: string;
}

const CtyPromptInput = React.forwardRef<HTMLTextAreaElement, CtyPromptInputProps>(
  ({ classNames = {}, ...props }, ref) => {

    const { inputHeight, handleSendClick, enableImage = false, enableSendBtn = false, enableTranslation = false, enableSpeak = false, enableSearch = false, isSpeechMode = false, setIsSpeechMode, triggerEveryInputChange = false, elementType, handleImageUpload, enableMention = false, enableLiveChat = false, clearInputOnSend = true, isSearchSpinnerLoading = false } = props;
    const { isSocketLoading, promptMessage } = useSelector((state: RootState) => state.chatAdvisorSliceReducer);
    const [inputValue, setInputValue] = useState<string>('');
    const [isListening, setIsListening] = useState<boolean>(false);
    const [selectedKey, setSelectedKey] = useState<string>(SpeechUtility.getLanguage() ?? "en-US");
    const dispatch = useAppDispatch();
    const { allAdvisors } = useSelector((state: RootState) => state.advisor);
    const [filteredAdvisors, setFilteredAdvisors] = useState<Advisor[]>([]);
    const [showAdvisorDropdown, setShowAdvisorDropdown] = useState(false);
    const [mentionIndex, setMentionIndex] = useState<number | null>(null);
    const defaultAdvisorLogo = generateCircuitryLogoSvgBlobUrl('#d4007e');
    const [selectedAdvisor, setSelectedAdvisor] = useState<MentionAdvisor | null>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setShowAdvisorDropdown(false);
        }
      };

      if (showAdvisorDropdown) {
        document.addEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [showAdvisorDropdown]);

    useEffect(() => {
      dispatch(updateCurrentChatMentionedEntities(selectedAdvisor));
    }, [selectedAdvisor]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const selectedUploadFileType = useRef<React.Key>('ocr');
    const handleSubmit = (e?: React.FormEvent) => {
      e?.preventDefault();
      if (!inputValue?.trim()) return;
      handleSendClick(inputValue);
      clearInputOnSend && setInputValue('');
    };
    const handleDropdownAction = (key: React.Key) => {
      //clear input file before opening it
      if (fileInputRef.current) fileInputRef.current.value = '';
      if (key === 'cameraocr') {
        handleImageUpload?.(key, null);
      } else {
        selectedUploadFileType.current = key;
        fileInputRef.current?.click();
      }


    };
    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = e.target.value;
      setInputValue(value);

      if (enableMention) {
        const lastAtIndex = value.lastIndexOf("@");
        if (lastAtIndex !== -1) {
          const mentionQuery = value.slice(lastAtIndex + 1);

          if (mentionQuery.trim() === "") {
            const availableAdvisors = allAdvisors.filter((advisor: Advisor) => advisor.advisor_id !== selectedAdvisor?.advisor_id);
            setFilteredAdvisors(availableAdvisors);
            setShowAdvisorDropdown(availableAdvisors.length > 0);
            setMentionIndex(lastAtIndex);
          } else {
            const filtered = allAdvisors
              .filter((advisor: Advisor) => advisor.name.toLowerCase().includes(mentionQuery.toLowerCase()))
              .filter((advisor: Advisor) => advisor.advisor_id !== selectedAdvisor?.advisor_id); // Exclude selected advisor

            setFilteredAdvisors(filtered);
            setShowAdvisorDropdown(filtered.length > 0);
            setMentionIndex(lastAtIndex);
          }
        } else {
          setShowAdvisorDropdown(false);
          setMentionIndex(null);
        }
      } else {
        setShowAdvisorDropdown(false);
        setMentionIndex(null);
      }

      triggerEveryInputChange && handleSendClick(value);
    };


    const handleAdvisorSelect = (advisor: MentionAdvisor) => {
      if (mentionIndex === null) return;

      const beforeMention = inputValue.slice(0, mentionIndex);
      const afterMention = inputValue.slice(mentionIndex).split(" ").slice(1).join(" ");

      const newValue = `${beforeMention}${afterMention}`.trim(); // Remove mention text

      setInputValue(newValue);
      setShowAdvisorDropdown(false);
      setMentionIndex(null);

      // Set only a single advisor
      setSelectedAdvisor(advisor);
      dispatch(updateCurrentChatMentionedEntities(advisor)); // Store it in Redux
    };




    useEffect(() => {
      if (isSpeechMode && !isListening && inputValue?.trim().length > 0) {
        handleSendClick(inputValue);
        setInputValue('');
      }
    }, [isListening]);

    useEffect(() => {
      setInputValue(promptMessage);
    }, [promptMessage]);

    const handleDropDown = (key: string) => {
      setSelectedKey(key);
      SpeechUtility.destroyInstance();
      SpeechUtility.setLanguage(key);
    };

    const handleSpeechAction = async () => {
      setIsSpeechMode?.(true);
      (await SpeechUtility.getInstance(dispatch)).sttFromMic(isListening, setIsListening, inputValue, setInputValue);
    };

    const handleLiveChat = () => {
      if (!document.getElementById("hs-script-loader")) {
        const script = document.createElement("script");
        script.src = `//js.hs-scripts.com/44503099.js`;
        script.id = "hs-script-loader";
        script.async = true;
        script.defer = true;

        // Add load event listener before appending the script
        script.addEventListener('load', () => {
          // Wait for HubSpot to initialize
          const checkHubSpot = setInterval(() => {
            // @ts-expect-error HubSpotConversations is not defined
            const HubSpotConversations = window.HubSpotConversations;
            if (HubSpotConversations) {
              clearInterval(checkHubSpot);
              setTimeout(() => {
                HubSpotConversations.widget.open();
              }, 1000);
              HubSpotConversations.on('widgetClosed', () => {
                HubSpotConversations.widget.remove();
              });
            }
          }, 100);
        });
        document.body.appendChild(script);
      } else {
        // @ts-expect-error HubSpotConversations is not defined
        const HubSpotConversations = window.HubSpotConversations;
        if (!HubSpotConversations.widget.status().loaded) {
          HubSpotConversations.widget.load();
        }
        HubSpotConversations.widget.open();
      }
    }

    const startContent = () => {
      const viewSearchIcon = !isSearchSpinnerLoading && enableSearch;
      return (
        <div className='relative'>
          {viewSearchIcon && <div className='flex items-center justify-start p-1'>
            <Icon icon="akar-icons:search" width="1.3em" height="1.3em" style={{ color: '#98A2B3' }} />
          </div>}
          {isSearchSpinnerLoading && <Spinner size="sm" />}
        </div>
      );
    };

    const endContent = () => {
      return (
        <div className="absolute right-0 h-full flex items-end justify-end gap-1 bg-redd-300">
          {enableLiveChat &&
            <CtyButton id='live-chat' isIconOnly tooltipContent="Live Chat" radius="full" size="sm" variant="light" onPress={handleLiveChat}>
              <Icon className="text-default-500" icon="fluent:people-chat-16-regular" width={20} />
            </CtyButton>
          }
          {enableImage &&
            <div className="pr-2">
              <Dropdown>
                <DropdownTrigger aria-label="Upload Image" title='Upload Image'>
                  <Icon className="text-default-500 cursor-pointer" icon="solar:gallery-minimalistic-linear" width={20} height={30} />
                </DropdownTrigger>
                <DropdownMenu aria-label="Static Actions" onAction={handleDropdownAction}>
                  <DropdownItem key="ocr">Upload Image</DropdownItem>
                  <DropdownItem key="barcode">Upload Barcode</DropdownItem>
                  <DropdownItem key="cameraocr">Take Photo</DropdownItem>
                </DropdownMenu>
              </Dropdown>

              {/* Hidden input for file upload (Camera) */}
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                capture="environment" // Opens the camera by default
                style={{ display: "none" }}
                onChange={(e) => handleImageUpload?.(selectedUploadFileType?.current, e)}
              />
            </div>
          }
          {enableTranslation && enableSpeak && <SearchableDropdown selectedKey={selectedKey} className='max-h-[200px] overflow-y-auto' onSelectionChange={(key) => handleDropDown(key)} availableLanguages={SpeechUtility.translateLanguages} />}
          {enableSpeak &&
            <CtyButton id={ID_CONSTANTS.chatSpeakBtn} isIconOnly tooltipContent="Speak" radius="full" size="sm" variant="light" onPress={handleSpeechAction}>
              <div className="relative">
                {isListening && <Spinner className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />}
                <Icon className="text-default-500" icon={isListening ? "proicons:soundwave" : "proicons:microphone-off"} width={isListening ? 22 : 20} style={isListening ? { color: "#b51a1a" } : undefined} />
              </div>
            </CtyButton>
          }
          {enableSendBtn && <CtyButton
            tooltipContent="Send message"
            id='chatsend-btn'
            isIconOnly
            isDisabled={isSocketLoading || !inputValue}
            radius="lg"
            size="sm"
            variant="light"
            onPress={() => handleSubmit()}
          >
            <Icon
              className={cn(
                "[&>path]:stroke-[2px]",
                !inputValue ? "text-default-600" : "text-primary-foreground ",
              )}
              icon="icon-park-outline:send-one"
              width={20}
              color="DD3399"
            />
          </CtyButton>}
        </div>
      );
    };
    const handleRemoveSelectedAdvisor = () => {
      setSelectedAdvisor(null); // Clear selection
      dispatch(updateCurrentChatMentionedEntities({})); // Clear Redux state
    };
    return (
      <div className="relative w-full">
        {
          isListening ? <MicrophoneWave handleSpeechAction={handleSpeechAction} /> :
            <div className="relative w-full">
              {showAdvisorDropdown && (
                <div ref={dropdownRef} className="absolute bottom-full mb-2 bg-white border shadow-md rounded-lg w-full max-h-40 overflow-auto z-50 p-2">
                  {filteredAdvisors.map((advisor) => (
                    <CtyButton
                      key={advisor.advisor_id}
                      className="p-2 cursor-pointer bg:white hover:bg-gray-200 flex gap-2 items-center"
                      onPress={() => handleAdvisorSelect({ advisor_id: advisor.advisor_id, name: advisor.name })}
                      variant='light'
                    >
                      <img src={advisor.assets?.[0]?.s3_path || defaultAdvisorLogo} alt={advisor.name} width={20} height={20} className="rounded-full" />
                      <span className='font-semibold text-sm text-gray-800'>{advisor.name}</span>
                      <span className='text-xs text-gray-500'>{advisor.description}</span>
                    </CtyButton>
                  ))}
                </div>
              )}

              {selectedAdvisor && (
                <div className="flex gap-2 p-2">
                  <div className="bg-[whitesmoke] rounded-full px-3 py-1 flex justify-between items-center gap-2 w-full">
                    <div className="flex items-center gap-3">
                      <img src={allAdvisors.find((advisor: Advisor) => advisor.advisor_id === selectedAdvisor.advisor_id)?.assets?.[0]?.s3_path || defaultAdvisorLogo}
                        alt={selectedAdvisor.name} width={15} height={15} className="rounded-full" />
                      <span>{selectedAdvisor.name}</span>
                    </div>
                    <Icon icon="zondicons:close-solid" width="20" height="20"
                      onClick={handleRemoveSelectedAdvisor} className="cursor-pointer" />
                  </div>
                </div>
              )}

              {elementType === promptInputType.TEXTAREA &&
                <Textarea
                  ref={ref}
                  aria-label="Prompt"
                  className={`${inputHeight ?? 'min-h-[40px]'} `}
                  classNames={{
                    ...classNames,
                    label: cn("hidden", classNames?.label),
                    input: cn(
                      "py-0",
                      classNames?.input,
                    ),
                  }}
                  onChange={handleInputChange}
                  minRows={1}
                  value={inputValue}
                  onValueChange={setInputValue}
                  onKeyDown={(e) => (e.key === 'Enter' && !e.shiftKey) && handleSubmit(e)}
                  placeholder="Enter a prompt here"
                  radius="none"
                  endContent={endContent()}
                  startContent={startContent()}
                  variant="flat"
                  disabled={isSocketLoading}
                  autoFocus={true}
                  {...props}
                />


              }
              {
                elementType === promptInputType.INPUT &&
                <Input
                  aria-label="Prompt"
                  size={props.size}
                  type="text"
                  endContent={endContent()}
                  startContent={startContent()}
                  placeholder={props.placeholder}
                  id={props.id}
                  onKeyDown={(e) => (e.key === 'Enter' && !e.shiftKey) && handleSubmit(e)}
                  value={inputValue}
                  onValueChange={setInputValue}
                  className={`min-w-sm ${inputHeight ?? 'min-h-[40px]'}`}
                  disabled={isSocketLoading}
                  autoFocus={true}
                  onChange={handleInputChange}
                />
              }


            </div>
        }
      </div>

    );
  },
);

export default CtyPromptInput;
export { CtyPromptInput };

CtyPromptInput.displayName = "CtyPromptInput";
