import { createSlice } from '@reduxjs/toolkit';

export const GLOBAL_CONVERSATION_SLICE_FEATURE_KEY = 'globalConversationSlice';

export interface ConversationItem {
    advisorID: string,
    senderID: string;
}

export interface ConversationSliceState {
    searchText: string;
    filteredItems: ConversationItem[];
}

export const initialConversationSliceState: ConversationSliceState = {
    searchText: '',
    filteredItems: []
};

export const globalConversationHistorySliceState = createSlice({
    name: GLOBAL_CONVERSATION_SLICE_FEATURE_KEY,
    initialState: initialConversationSliceState,
    reducers: {

        updateFilteredConversation(state, action) {
            state.filteredItems = action.payload;
        },

        updateSearchText(state, action) {
            state.searchText = action.payload;
        }
    }
});

export const globalConversationSliceReducer = globalConversationHistorySliceState.reducer;
export const { updateFilteredConversation, updateSearchText } = globalConversationHistorySliceState.actions;