import React, { FC, useRef, useEffect } from "react";
import { Icon } from "@iconify/react";

interface MicrophoneWaveProps {
    /** Function called when the microphone icon is clicked */
    handleSpeechAction: () => void;
}

const MicrophoneWave: FC<MicrophoneWaveProps> = ({ handleSpeechAction }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // Set canvas dimensions based on its parent element
        const setCanvasDimensions = () => {
            const parent = canvas.parentElement;
            if (parent) {
                canvas.width = parent.clientWidth;
                canvas.height = parent.clientHeight;
            }
        };

        setCanvasDimensions();

        const turbulenceFactor = 0.25;
        let maxAmplitude = canvas.height / 3.5; // Max amplitude of the wave
        const baseLine = canvas.height / 2; // Vertical center of the canvas
        const numberOfWaves = 10;
        let globalTime = 0;

        // Create a gradient for the waves
        function createGradient(): CanvasGradient {
            const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
            gradient.addColorStop(0, "rgba(255, 25, 255, 0.2)");
            gradient.addColorStop(0.5, "rgba(25, 255, 255, 0.75)");
            gradient.addColorStop(1, "rgba(255, 255, 25, 0.2)");
            return gradient;
        }
        const gradient = createGradient();

        // Generate a smooth wave when there is no sound
        function generateSmoothWave(
            dataArray: Uint8Array,
            frequency = 0.1,
            amplitude = 64
        ): Uint8Array {
            const array = new Uint8Array(100);
            for (let i = 0; i < array.length; i++) {
                array[i] = (Math.sin(i * frequency + globalTime) + 1) * amplitude;
            }
            return array;
        }

        // Draw the wave on the canvas
        function drawWave(dataArray: Uint8Array, analyser: AnalyserNode): void {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            globalTime += 0.05;

            for (let j = 0; j < numberOfWaves; j++) {
                ctx.beginPath();
                ctx.lineWidth = 2;
                ctx.strokeStyle = gradient;

                let x = 0;
                const sliceWidth = canvas.width / dataArray.length;
                let lastX = 0;
                let lastY = baseLine;

                for (let i = 0; i < dataArray.length; i++) {
                    const v = dataArray[i] / 96.0;
                    const mid = dataArray.length / 2;
                    const distanceFromMid = Math.abs(i - mid) / mid;
                    const dampFactor = 1 - Math.pow((2 * i) / dataArray.length - 1, 2);
                    const amplitude = maxAmplitude * dampFactor * (1 - distanceFromMid);
                    const isWaveInverted = j % 2 ? 1 : -1;
                    const frequency = isWaveInverted * (0.05 + turbulenceFactor);
                    const y =
                        baseLine + Math.sin(i * frequency + globalTime + j) * amplitude * v;

                    if (i === 0) {
                        ctx.moveTo(x, y);
                    } else {
                        const xc = (x + lastX) / 2;
                        const yc = (y + lastY) / 2;
                        ctx.quadraticCurveTo(lastX, lastY, xc, yc);
                    }

                    lastX = x;
                    lastY = y;
                    x += sliceWidth;
                }

                ctx.lineTo(canvas.width, lastY);
                ctx.stroke();
            }

            animationFrameId = requestAnimationFrame(() =>
                animateWaves(dataArray, analyser)
            );
        }

        // Animate the waves based on microphone input or smooth wave if there's no sound
        function animateWaves(dataArray: Uint8Array, analyser: AnalyserNode): void {
            const isSpeaking = dataArray.some((value) => value > 0);
            if (isSpeaking) {
                analyser.getByteFrequencyData(dataArray);
            } else {
                dataArray = generateSmoothWave(dataArray, 0.05, 16);
            }
            drawWave(dataArray, analyser);
        }

        let animationFrameId = 0;
        let mediaStream: MediaStream | null = null;
        let audioContext: AudioContext | null = null;

        // Get microphone access and start the animation
        navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then((stream: MediaStream) => {
                mediaStream = stream;
                audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const analyser = audioContext.createAnalyser();
                const microphone = audioContext.createMediaStreamSource(stream);
                microphone.connect(analyser);
                const dataArray = new Uint8Array(analyser.frequencyBinCount);
                // Use a portion of the data array for the wave effect
                const waves = dataArray.slice(0, 250);
                animateWaves(waves, analyser);
            })
            .catch((error: any) => {
                console.error("Access to microphone denied", error);
            });

        // Update canvas dimensions on window resize
        const handleResize = () => {
            setCanvasDimensions();
            maxAmplitude = canvas.height / 3.5;
        };
        window.addEventListener("resize", handleResize);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
            if (audioContext) {
                audioContext.close();
            }
            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => track.stop());
            }
            cancelAnimationFrame(animationFrameId);
        };
    }, []);

    return (
        <div className="w-full h-full relative bg-gradient-to-br from-[#141E30] to-[#243B55] text-white flex flex-col items-center">
            <p className="mt-4">Go ahead, I'm listening</p>
            <canvas ref={canvasRef} className="h-[100px]" />
            <div className="flex justify-center cursor-pointer mb-4">
                <Icon
                    icon="pepicons-pop:microphone-circle"
                    width="36"
                    height="36"
                    style={{ color: "#fff" }}
                    onClick={handleSpeechAction}
                />
            </div>
        </div>
    );
};

export default MicrophoneWave;
