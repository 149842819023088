const INITIAL_VISIBLE_COLUMNS = ["title", "agent", "date", "quality", "actions"];
const columns = [
  { name: "id", uid: "id" },
  { name: "Title", uid: "title" },
  // { name: "User", uid: "user" },
  { name: "Advisor Name", uid: "agent" },
  { name: "Posted Date", uid: "date" },
  { name: "Feedback", uid: "quality" },
  { name: "Actions", uid: "actions" },
];
const KNOWLEDGE_COLUMNS = [
  { name: "id", uid: "id" },
  { name: "File Name", uid: "file_name" },
  { name: "Progress", uid: "progress" },
  { name: "Status", uid: "status" },
  { name: "Size", uid: "size" },
  { name: "Date", uid: "date" },
  { name: "File Type", uid: "type" },
  { name: "Actions", uid: "actions" },
];
const ID_CONSTANTS = {
  chatAdvisorLogo: "chat-advisor-logo",
  chatAdvisorWelcomeMessage: "chat-advisor-welcome-message",
  chatAdvisorName: "chat-advisor-name",
  chatAdvisorDescription: "chat-advisor-description",
  converseImageUploadBtn: "converse-image-upload-btn",
  chatSpeechLanguageBtn: "chat-speech-language-btn-",
  chatTextTransformBtn: 'chat-text-transform-btn',
  chatSpeakBtn: 'chatspeak-btn',
  sessionId: "session-id-",
  knowledgeSourceText: "knowledge-source-text",
  expertSource: "expert-source-",
  viewSource: "view-source-",
  logoLabel: "logo-label",
  trainKnowledgeText: "trainknowledge-text",
  trainUrlText: "trainurl-text",
  urlSearchInput: "urlsearch-input",
  groupBtn: "group-btn",
  cancelUrlBtn: "cancel-url-btn",
  uploadUrlBtn: "upload-url-btn",
  fileName: "filename-",
  knowledgeTableRefreshBtn: "knowledgetable-refresh-btn",
  knowledgeTableUpdateBtn: "knowledgetable-update-btn",
  uploadCard: "-upload-card",
};

const KNOWLEDGE_INITIAL_VISIBLE_COLUMNS = ["file_name", "progress", "date", "status", "size", "type", "actions"];

const SourceElementType = Object.freeze({
  TEXT: "text",
  TABLE: "table",
  URL: "url",
  YOUTUBE: "youtube",
  AUDIO: [
    "mp3", "wav", "aac", "ogg", "flac", "m4a", "wma", "opus", "aiff", "alac", "amr"
  ],
  VIDEO: [
    "mp4", "mkv", "avi", "mov", "flv", "wmv", "webm", "mpeg", "mpg", "3gp", "ogv"
  ],
  IMAGE: "image",
  EXPERT_ANSWER: "expert_answer",
  KNOWLEDGE_ARTICLE: "knowledge_article",
  EXCEL: "excel",
  API: "api",
  PARTS_SVG: "parts_svg",
  MP3: "mp3",
  MP4: "mp4",
  ZIP: "zip",
});

const azureSupportedLanguages =
{
  "af-ZA": "Afrikaans",
  "am-ET": "Amharic",
  "ar-SA": "Arabic",
  "as-IN": "Assamese",
  "az-AZ": "Azerbaijani",
  "ba-RU": "Bashkir",
  "bg-BG": "Bulgarian",
  "bho-IN": "Bhojpuri",
  "bn-IN": "Bangla",
  "bo-CN": "Tibetan",
  "brx-IN": "Bodo",
  "bs-BA": "Bosnian",
  "ca-ES": "Catalan",
  "cs-CZ": "Czech",
  "cy-GB": "Welsh",
  "da-DK": "Danish",
  "de-DE": "German",
  "doi-IN": "Dogri",
  "dsb-DE": "Lower Sorbian",
  "dv-MV": "Divehi",
  "el-GR": "Greek",
  "en-US": "English",
  "es-ES": "Spanish",
  "et-EE": "Estonian",
  "eu-ES": "Basque",
  "fa-IR": "Persian",
  "fi-FI": "Finnish",
  "fil-PH": "Filipino",
  "fj-FJ": "Fijian",
  "fo-FO": "Faroese",
  "fr-FR": "French",
  "fr-CA": "French (Canada)",
  "ga-IE": "Irish",
  "gl-ES": "Galician",
  "gom-IN": "Konkani",
  "gu-IN": "Gujarati",
  "ha-NG": "Hausa",
  "he-IL": "Hebrew",
  "hi-IN": "Hindi",
  "hne-IN": "Chhattisgarhi",
  "hr-HR": "Croatian",
  "hsb-DE": "Upper Sorbian",
  "ht-HT": "Haitian Creole",
  "hu-HU": "Hungarian",
  "hy-AM": "Armenian",
  "id-ID": "Indonesian",
  "ig-NG": "Igbo",
  "ikt-CA": "Inuinnaqtun",
  "is-IS": "Icelandic",
  "it-IT": "Italian",
  "iu-CA": "Inuktitut",
  "iu-Latn-CA": "Inuktitut (Latin)",
  "ja-JP": "Japanese",
  "ka-GE": "Georgian",
  "kk-KZ": "Kazakh",
  "km-KH": "Khmer",
  "kmr-TR": "Kurdish (Northern)",
  "kn-IN": "Kannada",
  "ko-KR": "Korean",
  "ks-IN": "Kashmiri",
  "ku-IQ": "Kurdish (Central)",
  "ky-KG": "Kyrgyz",
  "ln-CD": "Lingala",
  "lo-LA": "Lao",
  "lt-LT": "Lithuanian",
  "lug-UG": "Ganda",
  "lv-LV": "Latvian",
  "lzh-CN": "Chinese (Literary)",
  "mai-IN": "Maithili",
  "mg-MG": "Malagasy",
  "mi-NZ": "Māori",
  "mk-MK": "Macedonian",
  "ml-IN": "Malayalam",
  "mn-Cyrl-MN": "Mongolian (Cyrillic)",
  "mn-Mong-CN": "Mongolian (Traditional)",
  "mni-IN": "Manipuri",
  "mr-IN": "Marathi",
  "ms-MY": "Malay",
  "mt-MT": "Maltese",
  "mww-CN": "Hmong Daw",
  "my-MM": "Myanmar (Burmese)",
  "nb-NO": "Norwegian",
  "ne-NP": "Nepali",
  "nl-NL": "Dutch",
  "nso-ZA": "Sesotho sa Leboa",
  "nya-MW": "Nyanja",
  "or-IN": "Odia",
  "otq-MX": "Querétaro Otomi",
  "pa-IN": "Punjabi",
  "pl-PL": "Polish",
  "prs-AF": "Dari",
  "ps-AF": "Pashto",
  "pt-BR": "Portuguese (Brazil)",
  "pt-PT": "Portuguese (Portugal)",
  "ro-RO": "Romanian",
  "ru-RU": "Russian",
  "run-BI": "Rundi",
  "rw-RW": "Kinyarwanda",
  "sd-PK": "Sindhi",
  "si-LK": "Sinhala",
  "sk-SK": "Slovak",
  "sl-SI": "Slovenian",
  "sm-WS": "Samoan",
  "sn-ZW": "Shona",
  "so-SO": "Somali",
  "sq-AL": "Albanian",
  "sr-Cyrl-RS": "Serbian (Cyrillic)",
  "sr-Latn-RS": "Serbian (Latin)",
  "st-ZA": "Sesotho",
  "sv-SE": "Swedish",
  "sw-TZ": "Swahili",
  "ta-IN": "Tamil",
  "te-IN": "Telugu",
  "th-TH": "Thai",
  "ti-ET": "Tigrinya",
  "tk-TM": "Turkmen",
  "tlh-Latn-XX": "Klingon (Latin)",
  "tlh-Piqd-XX": "Klingon (pIqaD)",
  "tn-BW": "Setswana",
  "to-TO": "Tongan",
  "tr-TR": "Turkish",
  "tt-RU": "Tatar",
  "ty-PF": "Tahitian",
  "ug-CN": "Uyghur",
  "uk-UA": "Ukrainian",
  "ur-PK": "Urdu",
  "uz-UZ": "Uzbek (Latin)",
  "vi-VN": "Vietnamese",
  "xh-ZA": "Xhosa",
  "yo-NG": "Yoruba",
  "yua-MX": "Yucatec Maya",
  "yue-CN": "Cantonese (Traditional)",
  "zh-Hans-CN": "Chinese Simplified",
  "zh-Hant-TW": "Chinese Traditional",
  "zu-ZA": "Zulu"
};

enum promptInputType {
  TEXTAREA = 'Textarea',
  INPUT = 'Input'
}
export { INITIAL_VISIBLE_COLUMNS, columns, KNOWLEDGE_COLUMNS, KNOWLEDGE_INITIAL_VISIBLE_COLUMNS, ID_CONSTANTS, azureSupportedLanguages, promptInputType, SourceElementType };