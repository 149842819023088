import apiEndPoints from "../../apiconstants";
import { api, handleErrorResponse } from "../api";


export const globalCartApiSlice = api.injectEndpoints({

    endpoints: builder => ({
        postCartItem: builder.mutation<any, any>({
            query: (payload) => ({
                url: apiEndPoints?.updateCartItem,
                method: 'POST',
                body: payload
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: handleErrorResponse
        }),
        updateCartItem: builder.mutation<any, any>({
            query: (payload) => ({
                url: apiEndPoints?.updateCartItem,
                method: 'PATCH',
                body: payload
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: handleErrorResponse
        }),
        deleteCartItem: builder.mutation<any, any>({
            query: (payload) => ({
                url: `${apiEndPoints?.deleteCartItem}/${payload.cartID}`,
                method: 'DELETE',
                body: {
                    code: payload.code
                }
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: handleErrorResponse
        }),
        getCartItems: builder.query<any, any>({
            query: () => ({
                url: apiEndPoints?.getCartItems,
                method: 'GET',
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: handleErrorResponse
        }),
        deleteItemInCart: builder.mutation<any, any>({
            query: (payload) => ({
                url: `${apiEndPoints?.deleteItemInCart}/${payload.itemID}`,
                method: 'DELETE'
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: handleErrorResponse
        }),
        getCartCsvFile: builder.mutation<any, any>({
            query: (cartID) => ({
                url: `${apiEndPoints?.getCartCsvFile}/${cartID}/download`,
                method: 'GET',
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: handleErrorResponse
        }),
        placeCartOrder: builder.mutation<any, any>({
            query: (cartID) => ({
                url: `${apiEndPoints?.getCartCsvFile}/${cartID}/orders/generate`,
                method: 'POST',
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: handleErrorResponse
        })
    })

});


export const { useGetCartItemsQuery, usePostCartItemMutation, useDeleteCartItemMutation, useUpdateCartItemMutation, useDeleteItemInCartMutation, useGetCartCsvFileMutation, usePlaceCartOrderMutation } = globalCartApiSlice

