import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import * as d3 from 'd3';
import { useGetPdfLinkForConversationsMutation } from '@circuitry-ai/doc-data';
import { useParams } from 'react-router-dom';
import CtySpinner from '../spinner/CtySpinner';
import { CtyButton } from '../button/CtyButton';
import { Tooltip } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import { ZoomControls } from './ZoomControls';

interface PartsSvgViewerProps {
  svgUrl: string;
  isFileViewer?: boolean;
  currentActivePartsRowData?: any;
  setSelectedRowId?: any;
  activeSourceData?: any;
  filename?: string;
  partsListFromFileViewer?: any[]
}

// Loading spinner component
const LoadingSpinner = ({ loadingText }: { loadingText: string }) => (
  <div className="flex justify-center items-center min-h-[500px]">
    <CtySpinner size="md" color="primary" />
  </div>
);

function ensureSvgOverflowVisible(svgText: string): string {
  const svgTagRegex = /<svg[^>]*>/;
  const match = svgText.match(svgTagRegex);

  if (match) {
    const svgTag = match[0];
    if (!/overflow=["']visible["']/.test(svgTag)) {
      const updatedSvgTag = svgTag.replace(/<svg/, '<svg overflow="visible"');
      return svgText.replace(svgTag, updatedSvgTag);
    }
  }
  return svgText;
}

interface PartInfo {
  parts_number: string;
  description: string;
}

interface SVGElementWithMethods extends SVGElement {
  getScreenCTM(): DOMMatrix | null;
  getBBox(): DOMRect;
}

const PartsSvgViewer: React.FC<PartsSvgViewerProps> = ({
  svgUrl,
  isFileViewer,
  currentActivePartsRowData,
  setSelectedRowId,
  activeSourceData,
  filename, partsListFromFileViewer,
}) => {
  const partsListInline = useMemo(() => {
    return activeSourceData?.element_refs?.[0]?.text || [];
  }, [activeSourceData]);
  const partsList = isFileViewer ? partsListFromFileViewer : partsListInline;
  const svgContainerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const zoomRef = useRef<d3.ZoomBehavior<SVGSVGElement, unknown> | null>(null);
  const [tooltipData, setTooltipData] = useState<PartInfo | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [svgLoadError, setSvgLoadError] = useState<string | null>(null);
  const fileNameFromParams = useParams<{ fileName: string }>();
  const fileName = isFileViewer ? filename : fileNameFromParams?.fileName || '';
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const activeReferenceNumber = isFileViewer
    ? currentActivePartsRowData?.[0]?.referenceNumber
    : currentActivePartsRowData?.[1]?.props?.children;

  const getSvgScreenCoordinates = (
    element: SVGElement
  ): { x: number; y: number } => {
    const svgElement = svgRef.current;
    if (!svgElement || !svgContainerRef.current) return { x: 0, y: 0 };

    const svgElementWithMethods = element as SVGElementWithMethods;
    const ctm = svgElementWithMethods.getScreenCTM();
    if (!ctm) return { x: 0, y: 0 };

    const bbox = svgElementWithMethods.getBBox();
    if (!bbox) return { x: 0, y: 0 };

    const centerX = bbox.x + bbox.width / 2;
    const centerY = bbox.y + bbox.height / 2;

    const point = svgElement.createSVGPoint();
    point.x = centerX;
    point.y = centerY;
    const screenPoint = point.matrixTransform(ctm);

    const containerRect = svgContainerRef.current.getBoundingClientRect();

    return {
      x: screenPoint.x - containerRect.left,
      y: screenPoint.y - containerRect.top,
    };
  };

  const handleTextClick = useCallback(
    (
      textElement: d3.Selection<SVGTextElement, unknown, null, undefined>,
      part: any,
      isTableRowClicked: boolean
    ) => {
      if (!textElement.node() || !svgRef.current || !svgContainerRef.current)
        return;

      const coords = getSvgScreenCoordinates(textElement.node() as SVGElement);
      const toolTipData = isFileViewer
        ? {
          parts_number: isTableRowClicked
            ? currentActivePartsRowData?.[0]?.parts_number
            : part?.parts_number,
          description: isTableRowClicked
            ? currentActivePartsRowData?.[0]?.name
            : part?.name,
        }
        : {
          parts_number: isTableRowClicked
            ? currentActivePartsRowData?.[2]?.props?.children
            : part?.part_info?.parts_number ?? '',
          description: isTableRowClicked
            ? currentActivePartsRowData?.[3]?.props?.children
            : part?.part_info?.description ?? '',
        };

      if (!isFileViewer && !isTableRowClicked) {
        const tableRowId =
          part.reference_number +
          '-' +
          part.part_info.parts_number +
          '-' +
          part.part_info.description;
        setSelectedRowId && setSelectedRowId(tableRowId);
      }

      setTooltipData(toolTipData);
      setTooltipPosition(coords);
    },
    [currentActivePartsRowData, isFileViewer, setSelectedRowId]
  );

  const updatePartsHighlighting = useCallback(() => {
    if (!svgRef.current) return;

    const svgElement = d3.select<SVGSVGElement, unknown>(svgRef.current);
    const textElements = svgElement.selectAll<SVGTextElement, unknown>(
      'g text'
    );

    textElements.each(function () {
      const textElement = d3.select<SVGTextElement, unknown>(this);
      const textContent = textElement.text().trim();

      const part = isFileViewer
        ? partsList.find((p: any) => p.referenceNumber === textContent)
        : partsList.find((p: any) => p.reference_number === textContent);

      if (part) {
        textElement
          .classed('highlighted', true)
          .on('click', () => handleTextClick(textElement as any, part, false));
      }
    });

    if (activeReferenceNumber) {
      const targetTextElement = textElements.filter(
        (_, i, nodes) =>
          d3.select<SVGTextElement, unknown>(nodes[i]).text().trim() ===
          activeReferenceNumber
      );

      if (targetTextElement.node()) {
        const part = isFileViewer
          ? currentActivePartsRowData?.[0]
          : partsList.find(
            (p: any) => p.reference_number === activeReferenceNumber
          );
        handleTextClick(targetTextElement as any, part, true);
      }
    }
  }, [partsList, activeReferenceNumber, currentActivePartsRowData, handleTextClick, isFileViewer]);

  useEffect(() => {
    const loadSvg = async () => {
      setIsLoading(true);
      setSvgLoadError(null);
      if (!svgUrl) {
        setSvgLoadError('No SVG URL provided');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(svgUrl);
        const svgText = await response.text();
        const updatedSvgText = ensureSvgOverflowVisible(svgText);

        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(
          updatedSvgText,
          'image/svg+xml'
        );
        const svgElement =
          svgDocument.documentElement as unknown as SVGSVGElement;

        if (svgContainerRef.current) {
          svgContainerRef.current.innerHTML = '';
          svgContainerRef.current.appendChild(svgElement);

          // Get the SVG's bounding box
          const bbox = svgElement.getBBox();

          // Add some padding (e.g., 5%)
          const padding = 0.05;
          const width = bbox.width * (1 + padding * 2);
          const height = bbox.height * (1 + padding * 2);
          const x = bbox.x - (bbox.width * padding);
          const y = bbox.y - (bbox.height * padding);

          // Set viewBox to match content bounds
          svgElement.setAttribute(
            "viewBox",
            `${x} ${y} ${width} ${height}`
          );

          // Set width and height to 100%
          svgElement.setAttribute("width", "100%");
          svgElement.setAttribute("height", "100%");

          // Add preserveAspectRatio to ensure proper scaling
          svgElement.setAttribute("preserveAspectRatio", "xMidYMid meet");
        }

        svgRef.current = svgElement;


        const svgSelection = d3.select<SVGSVGElement, unknown>(svgElement);
        const zoomGroup = svgSelection.append('g');

        svgSelection.selectAll(':scope > *:not(g)').each(function () {
          const node = this as Node;
          zoomGroup.node()?.appendChild(node);
        });

        svgSelection
          .selectAll(':scope > g')
          .filter(function () {
            return this !== zoomGroup.node();
          })
          .each(function () {
            const node = this as Node;
            zoomGroup.node()?.appendChild(node);
          });

        const zoom = d3
          .zoom<SVGSVGElement, unknown>()
          .scaleExtent([0.1, 200])
          .on('zoom', (event) => {
            zoomGroup.attr('transform', event.transform);
          });

        svgSelection.call(zoom);
        // Add initial transform to fit SVG in view
        const bounds = svgElement.getBBox();
        const containerWidth = svgContainerRef.current?.clientWidth || 0;
        const containerHeight = svgContainerRef.current?.clientHeight || 0;
        const scale =
          Math.min(
            containerWidth / bounds.width,
            containerHeight / bounds.height
          ) * 0.9;
        svgSelection.call(
          zoom.transform,
          d3.zoomIdentity
            .translate(
              (containerWidth - bounds.width * scale) / 2,
              (containerHeight - bounds.height * scale) / 2
            )
            .scale(scale)
        );
        zoomRef.current = zoom;

        updatePartsHighlighting();
      } catch (error) {
        console.error('Error loading SVG:', error);
        setSvgLoadError(
          error instanceof Error ? error.message : 'Unknown error loading SVG'
        );
      } finally {
        setIsLoading(false);
      }
    };

    loadSvg();
  }, [svgUrl, updatePartsHighlighting]);

  useEffect(() => {
    updatePartsHighlighting();
  }, [currentActivePartsRowData, updatePartsHighlighting]);

  const handleZoomIn = () => {
    if (svgRef.current && zoomRef.current) {
      d3.select<SVGSVGElement, unknown>(svgRef.current)
        .transition()
        .duration(200)
        .call(zoomRef.current.scaleBy, 1.2);
    }
  };

  const handleZoomOut = () => {
    if (svgRef.current && zoomRef.current) {
      d3.select<SVGSVGElement, unknown>(svgRef.current)
        .transition()
        .duration(200)
        .call(zoomRef.current.scaleBy, 0.8);
    }
  };

  const handleReset = () => {
    if (svgRef.current && zoomRef.current) {
      d3.select<SVGSVGElement, unknown>(svgRef.current)
        .transition()
        .duration(200)
        .call(zoomRef.current.transform, d3.zoomIdentity);
    }
  };
  useEffect(() => {
    setTimeout(() => handleReset(), 1500);
  }, [svgUrl]);

  return (
    <div className="flex flex-col h-full min-h-[400px] w-full relative">
      {fileName && (
        <div className="flex flex-wrap w-full gap-10 items-center justify-center p-4 border-b border-grey">
          <h3 className="font-bold text-center">{fileName}</h3>
        </div>
      )}
      {isLoading && <LoadingSpinner loadingText="Preparing Svg...." />}

      <div className="relative">
        <ZoomControls
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
          onReset={handleReset}
        />

        {svgLoadError ? (
          <div className="text-red-500 flex justify-center items-center h-[400px]">
            {svgLoadError}
          </div>
        ) : (
          <div className="relative">
            <div
              ref={svgContainerRef}
              className="w-full h-[600px] relative overflow-hidden flex items-center justify-center"
              style={{
                minWidth: '800px',
                margin: 'auto',
              }}
            ></div>
            <TooltipComponent
              tooltipData={tooltipData}
              tooltipPosition={tooltipPosition}
              setTooltipData={setTooltipData}
              setSelectedRowId={setSelectedRowId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface FetchError {
  status: number;
  data: {
    message?: string;
  };
}

interface PartSvgViewerInlineProps {
  message: any;
  advisorId: string;
  currentActivePartsRowData: any;
  setSelectedRowId: any;
}

const PartSvgViewerInline: React.FC<PartSvgViewerInlineProps> = ({
  message,
  advisorId,
  currentActivePartsRowData,
  setSelectedRowId,
}) => {
  const sourceIdData = message?.metaData?.[1]?.source_ids || [];

  const svgFiles = useMemo(
    () => sourceIdData.filter((source: any) => source.file_name),
    [sourceIdData]
  );

  useEffect(() => {
    setActiveSvgIndex(0);
  }, [svgFiles]);

  const [activeSvgIndex, setActiveSvgIndex] = useState(0);
  const [svgCache, setSvgCache] = useState<{ [fileName: string]: string }>({});
  const [elementRefs, setElementRefs] = useState<any[]>(() =>
    svgFiles.length > 0
      ? [{ source_id: svgFiles[activeSvgIndex].source_id, page_labels: [] }]
      : []
  );

  useEffect(() => {
    if (svgFiles.length > 0) {
      const newElementRef = [
        { source_id: svgFiles[activeSvgIndex].source_id, page_labels: [] },
      ];
      setElementRefs((prev) => {
        return JSON.stringify(prev) !== JSON.stringify(newElementRef)
          ? newElementRef
          : prev;
      });
    }
  }, [activeSvgIndex, svgFiles]);

  const [getPdfLink, { error, isError }] =
    useGetPdfLinkForConversationsMutation();
  const [svgUrl, setSvgUrl] = useState<string>('');
  const [isPartsDataLoading, setIsPartsDataLoading] = useState(false);

  const imgRef = useRef<any>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [imageScale, setImageScale] = useState(1);
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const dragStart = useRef({ x: 0, y: 0 });

  const handleImageZoomIn = () => {
    setImageScale((prev) => Math.min(prev * 1.2, 5));
  };

  const handleImageZoomOut = () => {
    setImageScale((prev) => Math.max(prev * 0.8, 0.5));
  };

  const handleImageReset = () => {
    setImageScale(1);
    setImagePosition({ x: 0, y: 0 });
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
    dragStart.current = {
      x: e.clientX - imagePosition.x,
      y: e.clientY - imagePosition.y,
    };
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isDragging) {
      setImagePosition({
        x: e.clientX - dragStart.current.x,
        y: e.clientY - dragStart.current.y,
      });
    }
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  useEffect(() => {
    const selectedRowFileName = currentActivePartsRowData?.[5]?.props?.children;
    if (selectedRowFileName) {
      const index = svgFiles.findIndex(
        (source: any) => source.file_name.trim() === selectedRowFileName.trim()
      );
      if (index !== -1 && index !== activeSvgIndex) {
        setActiveSvgIndex(index);
      }
    }
  }, [currentActivePartsRowData, svgFiles, activeSvgIndex]);

  useEffect(() => {
    if (svgFiles.length === 0) return;
    const activeSvg = svgFiles[activeSvgIndex];
    const activeFileName = activeSvg.file_name.trim();

    if (svgCache[activeFileName]) {
      setSvgUrl(svgCache[activeFileName]);
      return;
    }

    (async () => {
      try {
        setIsPartsDataLoading(true);
        const elementRef = [
          { source_id: activeSvg.source_id, page_labels: [] },
        ];
        const getPdfLinkRequestPayload = {
          advisor_id: advisorId,
          source_ids: elementRef,
        };
        const urlResponse = await getPdfLink(getPdfLinkRequestPayload).unwrap();
        const newUrl = urlResponse?.data?.[0]?.full_source_presigned_url || '';
        setSvgCache((prev) => ({ ...prev, [activeFileName]: newUrl }));
        setSvgUrl(newUrl);
      } catch (e) {
        console.error((e as Error)?.message || 'Error Fetching Image');
      } finally {
        setIsPartsDataLoading(false);
      }
    })();
  }, [activeSvgIndex, svgFiles, advisorId, getPdfLink, svgCache]);

  useEffect(() => {
    const container = imageContainerRef.current;
    if (!container) return;

    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
      const scaleFactor = e.deltaY > 0 ? 0.9 : 1.1;
      setImageScale((prev) => {
        const newScale = prev * scaleFactor;
        return Math.min(Math.max(newScale, 0.5), 5);
      });
    };

    container.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      container.removeEventListener('wheel', handleWheel);
    };
  }, [svgUrl]);

  const handlePrev = () => {
    setActiveSvgIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setActiveSvgIndex((prev) => Math.min(prev + 1, svgFiles.length - 1));
  };

  return (
    <div className="h-[100%] relative">
      {svgFiles?.length > 1 && (
        <div className="absolute inset-0 flex items-center justify-between px-4 pointer-events-none z-10">
          <button
            onClick={handlePrev}
            disabled={activeSvgIndex === 0}
            className={`btn-prev pointer-events-auto ${activeSvgIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            title="Previous"
            aria-label="Previous SVG"
          >
            <Icon
              icon="mingcute:left-fill"
              width="24"
              height="24"
              style={{ color: 'blue' }}
            />
          </button>
          <button
            onClick={handleNext}
            disabled={activeSvgIndex === svgFiles.length - 1}
            className={`btn-next pointer-events-auto ${activeSvgIndex === svgFiles.length - 1
              ? 'opacity-50 cursor-not-allowed'
              : ''
              }`}
            title="Next"
            aria-label="Next SVG"
          >
            <Icon
              icon="mingcute:right-fill"
              width="24"
              height="24"
              style={{ color: 'blue' }}
            />
          </button>
        </div>
      )}

      {svgFiles.length > 0 ? (
        <div className="w-full relative min-w-[800px]">
          {isError ? (
            <div className="text-red-500 flex justify-center items-center h-[600px]">
              {(error as FetchError)?.data?.message ??
                'Failed to load SVG, please try again later.'}
            </div>
          ) : isPartsDataLoading ? (
            <LoadingSpinner loadingText="Fetching Svg ...." />
          ) : (
            <div className="w-[100%] h-[100%]">
              {svgFiles[activeSvgIndex].file_name.trim().endsWith('svg') ? (
                <PartsSvgViewer
                  svgUrl={svgUrl}
                  isFileViewer={false}
                  currentActivePartsRowData={currentActivePartsRowData}
                  setSelectedRowId={setSelectedRowId}
                  activeSourceData={svgFiles[activeSvgIndex]}
                />
              ) : (
                <div
                  ref={imageContainerRef}
                  className="relative w-full max-w-[800px] mx-auto h-[100%] overflow-hidden"
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  <ZoomControls
                    onZoomIn={handleImageZoomIn}
                    onZoomOut={handleImageZoomOut}
                    onReset={handleImageReset}
                  />
                  <div
                    ref={imgRef}
                    className="w-full h-full flex items-center justify-center"
                    style={{
                      transform: `scale(${imageScale}) translate(${imagePosition.x}px, ${imagePosition.y}px)`,
                      transition: isDragging
                        ? 'none'
                        : 'transform 0.2s ease-out',
                      cursor: isDragging ? 'grabbing' : 'grab',
                      transformOrigin: 'center center',
                      touchAction: 'none',
                    }}
                  >
                    <img
                      src={svgUrl}
                      alt={svgFiles[activeSvgIndex].file_name}
                      className="max-h-full max-w-full w-auto h-auto object-contain"
                      draggable="false"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>No SVG files available.</div>
      )}
    </div>
  );
};

interface TooltipComponentProps {
  tooltipData: PartInfo | null;
  tooltipPosition: { x: number; y: number } | null;
  setTooltipData: (data: PartInfo | null) => void;
  setSelectedRowId?: (id: string) => void;
}

const TooltipComponent = ({
  tooltipData,
  tooltipPosition,
  setTooltipData,
  setSelectedRowId,
}: TooltipComponentProps) => {
  const handleCloseTooltip = () => {
    setTooltipData(null);
    setSelectedRowId && setSelectedRowId('');
  };

  return (
    <Tooltip
      isOpen={!!tooltipData}
      content={
        <div
          style={{
            maxWidth: '200px',
            wordBreak: 'break-word',
            paddingRight: '20px',
            position: 'relative',
          }}
        >
          <div style={{ pointerEvents: 'none' }}>
            <div>
              <strong>Part Number:</strong> {tooltipData?.parts_number}
            </div>
            <div>
              <strong>Description:</strong> {tooltipData?.description}
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '2px',
              right: '2px',
              pointerEvents: 'auto',
            }}
          >
            <CtyButton
              className="cursor-pointer text-white text-xs p-1 rounded-full min-w-0 bg-transparent"
              onPress={handleCloseTooltip}
              title="Close Tooltip"
            >
              ✕
            </CtyButton>
          </div>
        </div>
      }
      placement="top"
      className="bg-[#003A79] text-white p-2 rounded-lg shadow-lg"
      isDismissable={true}
      showArrow
      color="primary"
    >
      <div
        style={{
          position: 'absolute',
          left: `${tooltipPosition?.x}px`,
          top: `${tooltipPosition?.y}px`,
          transform: 'translate(-50%, -100%)',
          zIndex: 9999,
          pointerEvents: 'none',
        }}
      ></div>
    </Tooltip>
  );
};

export { PartSvgViewerInline, PartsSvgViewer };
