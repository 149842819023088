import { Icon } from '@iconify/react';

interface ZoomControlsProps {
  onZoomIn: () => void;
  onZoomOut: () => void;
  onReset: () => void;
}

export const ZoomControls: React.FC<ZoomControlsProps> = ({
  onZoomIn,
  onZoomOut,
  onReset
}) => {
  return (
    <div className="absolute top-4 left-4 flex gap-2 z-10">
      <div className="flex flex-col gap-2">
        <button 
          className="p-2 bg-gray-800 text-white rounded cursor-pointer" 
          onClick={onZoomIn} 
          title='Zoom In'
        >
          +
        </button>
        <button 
          className="p-2 bg-gray-800 text-white rounded cursor-pointer" 
          onClick={onZoomOut} 
          title='Zoom Out'
        >
          −
        </button>
        <button 
          className="p-2 bg-gray-800 text-white rounded cursor-pointer" 
          onClick={onReset} 
          title='Reset Zoom'
        >
          <Icon icon="system-uicons:reset" width="21" height="21" />
        </button>
      </div>
    </div>
  );
};