import React, { useState, useCallback, useRef } from 'react';
import { Accordion, AccordionItem, Button, Card, CardBody, Progress } from "@nextui-org/react";
import { Icon } from '@iconify/react';
import { useGenerateImageMutation, useGetImageModalDataMutation, useGetPdfLinkMutation, useUploadAgentImageMutation, useUploadFileToS3Mutation } from '@circuitry-ai/doc-data';
import { CtyButton, CtyShowToast } from '@circuitry-ai/doc-ui';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useLoadScript } from '@react-google-maps/api';

interface ImageInfo {
    classification_results: string;
    modified_ai_generation_results: {
        status: string;
        request: {
            id: string;
            timestamp: number;
            operations: number;
        };
        type: {
            ai_generated: number;
        };
        media: {
            id: string;
            uri: string;
        };
        confidence_score: number;
        ai_generation: boolean;
    };
    se_ai_generated_results: {
        status: string;
        request: {
            id: string;
            timestamp: number;
            operations: number;
        };
        type: {
            ai_generated: number;
        };
        media: {
            id: string;
            uri: string;
        };
        confidence_score: number;
        ai_generation: boolean;
    };
    duplicate_results: Array<{
        id: string;
        source_id: string;
        source_path: string;
        file_name: string;
        tenant_id: string;
        hash_diff: number;
    }>;
    metadata_results: {
        modified_GPS?: {
            latitude: number | null;
            longitude: number | null;
            altitude: number | null;
            timestamp: string | null;
            date: string | null;
            direction_ref: string | null;
            direction: number | null;
            bearing_ref: string | null;
            bearing: number | null;
        };
        [key: string]: any;
    };
    web_detection_results: {
        direct_matches: string[];
        similar_matches: string[];
    };
    tampering_results: string;
    similarity_results: Array<{
        id: string;
        source_id: string;
        source_path: string;
        filename: string | null;
        score: number;
        tenant_id: string;
    }>;
    message_id: string;
}

interface SourceId {
    source_id: string;
    page_labels: string[];
}

interface SourceIds {
    source_ids: SourceId[];
}



interface ImageScannerProps {
    onScanComplete?: (info: ImageInfo) => void;
    onScanError?: (error: Error) => void;
}

function ImageScanner({ onScanComplete, onScanError }: ImageScannerProps) {
    const [preview, setPreview] = useState<string | null>(null);
    const [scanning, setScanning] = useState(false);
    const [scanResult, setScanResult] = useState<ImageInfo | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [fileName, setFileName] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [uploadAgentImage] = useUploadAgentImageMutation();
    const [getImageModalData] = useGetImageModalDataMutation();
    const [uploadtos3] = useUploadFileToS3Mutation();
    const [isSimilarImageLoading, setIsSimilarImageLoading] = useState(-1);
    const [
        generateImage
    ] = useGenerateImageMutation();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDd1bRPw6pQoYVzblLHOM0Lu9y533oyz7A"
    });
    const handleFileSelect = useCallback(async (file: File) => {
        if (file && file.type.startsWith('image/')) {
            const previewUrl = URL.createObjectURL(file);
            setPreview(previewUrl);
            setFileName(file.name);
            setScanning(true);
            setScanResult(null);
            try {
                const payLoad = {
                    aiworker_id: "c8539d6e-cab0-4364-bdc7-7315d11d27b6",
                    data: [
                        {
                            filename: file.name,

                        }
                    ]
                };

                const uploadURL: any = await uploadAgentImage(payLoad).unwrap();
                console.log(uploadURL);
                await uploadtos3({
                    file,
                    presignedUrl: uploadURL?.[0]?.presigned_url,
                    CtyShowToast
                }).unwrap();
                await new Promise((resolve) => setTimeout(resolve, 5000));
                const imageModalPayLoad = {
                    name: "image_forensics",
                    payload: {
                        source_id: uploadURL?.[0]?.source_id,
                        aiworker_id: "c8539d6e-cab0-4364-bdc7-7315d11d27b6",
                    }
                };
                const imageModalData: any = await getImageModalData(imageModalPayLoad).unwrap();

                setScanResult(imageModalData);
                console.log('imageModalData', imageModalData)
                setScanning(false);



            } catch (error) {
                console.error(error);
            }
        }
    }, [onScanComplete, onScanError]);

    const handleRemoveFile = () => {
        setPreview(null);
        setFileName(null);
        setScanResult(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('image/')) {
            handleFileSelect(file);
        }
    };

    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            handleFileSelect(file);
        }
    };
    const [getPdfLink] = useGetPdfLinkMutation();
    const handleRescan = () => {
        console.log('handleRescan');
        setPreview(null);
        setScanResult(null);
        setFileName(null);
    };
    const handleSimilarImageClick = useCallback(async (match: ImageInfo['similarity_results'][0], index: number) => {
        try {
            setIsSimilarImageLoading(index);
            const ImageResponse = await generateImage({
                s3_paths: [match?.source_path],
            }).unwrap();
            ImageResponse?.data?.[0]?.presigned_url && window.open(ImageResponse?.data?.[0]?.presigned_url, '_blank');
            setIsSimilarImageLoading(-1);
        } catch (error) {
            console.error('Error fetching similar image:', error);
            CtyShowToast('error', 'Failed to load similar image');
            setIsSimilarImageLoading(-1);
        }
    }, [getPdfLink]);

    const renderMap = (gpsData: ImageInfo['metadata_results']['modified_GPS']) => {
        // if (!isLoaded || !gpsData) return <div>Loading Maps...</div>;
        // if (!gpsData.latitude || !gpsData.longitude) return null;

        const lat = gpsData?.latitude || 40.573339204027945;
        const lng = gpsData?.longitude || -74.11933042781544;
        if (isNaN(lat) || isNaN(lng)) return null;

        return (
            <div className='w-full h-[300px] mt-2'>
                <GoogleMap
                    mapContainerStyle={{
                        width: '100%',
                        height: '100%'
                    }}
                    center={{
                        lat,
                        lng
                    }}
                    zoom={15}
                >
                    <Marker
                        position={{
                            lat,
                            lng
                        }}
                    />
                </GoogleMap>
            </div>
        );
    };

    return (
        <div className="flex flex-col h-full">
            <div className="p-4 pb-0">
                <h2 className="text-2xl font-bold">Image Analysis</h2>
                <p className="text-sm text-default-500">Define some specific task templates and execute them. It can been used for the fixed business.</p>
            </div>
            <div className="flex-1 flex gap-4 p-4">
                {/* Left side - Image Preview and Upload */}
                <div className="w-1/2 flex flex-col gap-4">
                    {/* File Name Display */}

                    <Card className="flex-1">
                        {fileName &&
                            <div className="flex items-center justify-between px-3 py-1">
                                <div className="flex items-center gap-2">
                                    <Icon icon="material-symbols:image" className="text-default-400" />
                                    <span className="text-sm text-default-600 truncate">{fileName}</span>
                                </div>
                                <Button
                                    isIconOnly
                                    variant="light"
                                    size="sm"
                                    onClick={handleRemoveFile}
                                    className="text-danger"
                                    isDisabled={!fileName}
                                    title='Remove image'
                                >
                                    <Icon icon="material-symbols:close" />
                                </Button>
                            </div>
                        }
                        <CardBody className="p-0">
                            {preview ? (
                                <div className="relative h-full">
                                    <img
                                        src={preview}
                                        alt="Preview"
                                        className="w-full h-[100%] object-contain"
                                    />
                                    {scanning && (
                                        <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                                            <div className="text-white">Scanning...</div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <button
                                    className={`h-[100%] flex flex-col items-center justify-center p-4 cursor-pointer transition-colors
                                        ${isDragging ? 'bg-primary/10 border-2 border-primary' : 'hover:bg-default-100'}`}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept="image/*"
                                        className="hidden"
                                        onChange={handleFileInputChange}
                                    />
                                    <Icon icon="material-symbols:upload" className="w-12 h-12 text-default-400 mb-4" />
                                    <p className="text-default-600 text-center">
                                        {isDragging
                                            ? 'Drop the image here'
                                            : 'Drag & drop an image here, or click to select'}
                                    </p>
                                    <p className="text-sm text-default-500 mt-2">
                                        Supported formats: PNG, JPG, JPEG, GIF, BMP, WEBP
                                    </p>
                                </button>
                            )}
                        </CardBody>
                    </Card>


                </div>

                {/* Right side - Scan Results */}
                <div className="w-1/2">
                    <Card className="h-full">
                        <CardBody>
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold">Scan Results</h3>
                                {preview && (
                                    <Button
                                        startContent={<Icon icon="material-symbols:refresh" />}
                                        onClick={handleRescan}
                                        variant="flat"
                                        size="sm"
                                    >
                                        Rescan
                                    </Button>
                                )}
                            </div>

                            {scanResult ? (
                                <div className="space-y-4 flex flex-col gap-4 px-4">
                                    <div className='w-full flex flex-col gap-2 justify-center '>
                                        <p className="font-bold text-center ">Authentication Score</p>
                                        <div className=" flex items-center gap-2 flex-col ">
                                            <p className="font-medium mt-0">{scanResult?.modified_ai_generation_results?.confidence_score ? 100 - scanResult?.modified_ai_generation_results?.confidence_score * 100 + '%' : 'NA'}</p>

                                            {scanResult?.modified_ai_generation_results?.confidence_score && <Progress aria-label="Loading..." className="max-w-md" value={100 - scanResult?.modified_ai_generation_results?.confidence_score * 100} maxValue={100} color={100 - scanResult?.modified_ai_generation_results?.confidence_score > 50 ? 'success' : 'danger'} />}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className=" flex items-center gap-2">
                                        <label className="font-bold">Image Classification: </label>
                                        <p className="font-medium mt-0">{scanResult?.classification_results}</p>
                                    </div>
                                    <hr />
                                    <div className=" flex items-center gap-2">
                                        <label className="font-bold">Duplicate image: </label>
                                        <p className="font-medium">{scanResult?.duplicate_results?.length > 0 ? 'Yes' : 'No'}</p>
                                    </div>
                                    <hr />
                                    <div className=" flex items-center gap-2">
                                        <label className="font-bold">AI Generation: </label>
                                        <p className={`font-medium ${scanResult.modified_ai_generation_results?.ai_generation ? 'text-danger' : 'text-success'}`}>
                                            {scanResult.modified_ai_generation_results?.ai_generation ? 'Positive' : 'Negative'}
                                        </p>
                                    </div>
                                    <hr />
                                    <div className=" flex items-center gap-2">
                                        <label className="font-bold">Tampering Results: </label>
                                        <p >
                                            {scanResult?.tampering_results ? scanResult?.tampering_results : 'NA'}
                                        </p>
                                    </div>
                                    <hr />
                                    <div className="flex gap-2">
                                        <label className="font-bold max-w-content">Similar Image Matches: </label>
                                        <div className='w-[100%] flex justify-start flex-wrap'>
                                            {scanResult?.similarity_results?.map((match: any, index: number) => (
                                                <div key={match.source_id} >
                                                    <CtyButton
                                                        className='bg-none hover:bg-none text-[#5858e0] '
                                                        onPress={() => handleSimilarImageClick(match, index)}
                                                        variant='light'
                                                        size='sm'
                                                        color='white'
                                                        isLoading={isSimilarImageLoading === index ? true : false}
                                                    >
                                                        {match?.file_name || `Similar Image ${index + 1}`}
                                                    </CtyButton>
                                                </div>
                                            ))}
                                            {scanResult?.similarity_results?.length === 0 && (
                                                <p>No similar image matches found</p>
                                            )}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className=" flex items-center gap-2">
                                        <label className="font-bold">Web Detection: </label>
                                        <p>{scanResult?.web_detection_results?.direct_matches.length > 0 ? 'Positive' : 'Negative'}</p>
                                    </div>
                                    <hr />
                                    {scanResult?.metadata_results?.modified_GPS && (
                                        <>
                                            <div className="flex flex-col gap-2">
                                                <div className="flex items-center gap-2">
                                                    <label className="font-bold">GPS Location: </label>
                                                    <p>
                                                        {scanResult.metadata_results.modified_GPS.latitude && scanResult.metadata_results.modified_GPS.longitude
                                                            ? `Lat: ${scanResult.metadata_results.modified_GPS.latitude}, Long: ${scanResult.metadata_results.modified_GPS.longitude}`
                                                            : ''}
                                                    </p>
                                                </div>
                                                {renderMap(scanResult.metadata_results.modified_GPS)}
                                            </div>
                                            <hr />
                                        </>
                                    )}

                                    <Accordion>
                                        <AccordionItem className="font-bold" title="Metadata">
                                            {scanResult?.metadata_results && typeof scanResult?.metadata_results === 'object' &&
                                                Object.entries(scanResult?.metadata_results).map(([key, value]) => {
                                                    // Skip GPS data as it's now shown above
                                                    if (key === 'modified_GPS') return null;

                                                    // Skip empty objects
                                                    if (typeof value === 'object' && Object.keys(value).length === 0) return null;

                                                    // Format the value based on its type
                                                    let displayValue = '';
                                                    if (typeof value === 'object') {
                                                        displayValue = Object.entries(value)
                                                            .filter(([_, v]) => v !== null)
                                                            .map(([k, v]) => `${k}: ${v}`)
                                                            .join(', ');
                                                    } else if (typeof value === 'number') {
                                                        displayValue = Number.isInteger(value) ? value.toString() : value.toFixed(2);
                                                    } else if (typeof value === 'string' && value.length > 100) {
                                                        displayValue = value.substring(0, 100) + '...';
                                                    } else {
                                                        displayValue = String(value);
                                                    }

                                                    if (!displayValue) return null;

                                                    return (
                                                        <div key={key} className='px-4 py-2 font-medium flex items-start gap-2'>
                                                            <span className='font-bold min-w-[150px]'>{key}:</span>
                                                            <span className='flex-1'>{displayValue}</span>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </AccordionItem>
                                    </Accordion>

                                </div>
                            ) : (
                                <div className="h-full flex items-center justify-center text-default-500">
                                    {scanning ? 'Scanning image...' :
                                        "Upload an image to run an authentication check"}
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
}
export default ImageScanner;
