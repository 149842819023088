import { createSlice } from '@reduxjs/toolkit';
import { Advisor } from './advisortypes';
import { AdvisorAbility, SETTING_TYPE } from '../settings/settingstypes';
interface InitialState {
    activeChatAdvisor: Advisor | null
    isCreateAdvisorFlow: boolean,
    advisorAbilities: Record<SETTING_TYPE, AdvisorAbility>
    allAdvisors: Advisor[]

}
const initialState: InitialState = {
    activeChatAdvisor: null,
    isCreateAdvisorFlow: false,
    advisorAbilities: {} as Record<SETTING_TYPE, AdvisorAbility>,
    allAdvisors: []
}
const authSlice = createSlice({
    name: 'advisor',
    initialState,// Update the type of state.token
    reducers: {
        updateActiveChatAdvisor: (state, action) => {
            state.activeChatAdvisor = action.payload
        },
        updateCurrentAdvisorFlowType: (state, action) => {
            state.isCreateAdvisorFlow = action.payload
        },
        updateActiveAdvisorAbilities: (state, action) => {
            state.advisorAbilities = action.payload
        },
        updateAllAdvisors: (state, action) => {
            state.allAdvisors = action.payload
        }
    },
});

export default authSlice.reducer;
export const { updateActiveChatAdvisor, updateCurrentAdvisorFlowType, updateActiveAdvisorAbilities, updateAllAdvisors } = authSlice.actions;