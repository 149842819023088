import { type MiniMapNodeProps, useInternalNode } from '@xyflow/react';
import { ShapeComponents, ShapeNode } from '../shape/types';

// the custom minimap node is being used to render the shapes of the nodes in the minimap, too
function MiniMapNode({ id, width, height, x, y, selected }: Readonly<MiniMapNodeProps>) {
  // get the node data to render the shape accordingly
  const internalNode = useInternalNode<ShapeNode>(id);

  if (!internalNode) {
    return;
  }

  const { color='#ffffff', type } = internalNode.internals.userNode.data;
  const shape = internalNode.internals.userNode.type;
  if (shape === 'placeholder' || (!color || !type)) {
    return null;
  }

  const ShapeComponent = ShapeComponents[type];

  return (
    <>
      <defs>
        <linearGradient id={`minimap-stroke-${id}`} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#D4007E" stopOpacity="0.8" />
          <stop offset="100%" stopColor="#003A79" stopOpacity="0.8" />
        </linearGradient>
      </defs>
      <g
        transform={`translate(${x}, ${y})`}
        className={selected ? 'react-flow__minimap-node selected' : 'react-flow__minimap-node'}
      >
        <ShapeComponent
          width={width}
          height={height}
          fill={color}
          stroke={`url(#minimap-stroke-${id})`}
          strokeWidth={2}
        />
      </g>
    </>
  );
}

export default MiniMapNode;
