import { CtyButton, CtyShowToast, CtySpinner, JsonFormViewer } from "@circuitry-ai/doc-ui";
import { useGetClaimScoreMutation, useUploadFileToS3Mutation, useGeneratePresignedUrlMutation, useGetClaimScoreTableDataMutation } from "@circuitry-ai/doc-data";
import { Icon } from "@iconify/react";
import { Accordion, AccordionItem, Input, Select, SelectItem, Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useState } from "react";

/* eslint-disable-next-line */
export interface ScoreProps { }

const isArrayData = (data: any) => Array.isArray(data);

// Add helper function to get nested object value using path string
const getNestedValue = (obj: any, path: string) => {
  return path.split('.').reduce((acc, part) => {
    if (acc === null || acc === undefined) return acc;
    return acc[part];
  }, obj);
};

function TableView({ data }: { data: any[]; }) {
  if (data.length === 0) return null;
  const rowData = data[0];
  return (
    <Table aria-label="Data table" className="p-1">
      <TableHeader>
        {['Field', 'Value'].map((col) => (
          <TableColumn key={col}>{col}</TableColumn>
        ))}
      </TableHeader>
      <TableBody>
        {Object.entries(rowData).map(([key, value], keyIndex) => (
          <TableRow key={keyIndex + 'key'}>
            <TableCell>{key}</TableCell>
            <TableCell>{String(value)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function ScoreDecision(props: any) {
  return (
    <div className="w-full h-[774px] px-6 flex-col justify-start items-start gap-6 inline-flex">
      <div className="self-stretch h-[188px] p-6 bg-white rounded-xl shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#eaecf0] flex-col justify-start items-start gap-6 flex">
        <div className="self-stretch h-[140px] flex-col justify-start items-start gap-4 flex">
          <div className="self-stretch text-[#475466] text-sm font-bold font-['Poppins'] leading-tight">Total Score for {props.inputData.entityType} {props.inputData.entityCode}</div>
          <div className="self-stretch h-11 flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch h-11 text-center text-[#0f1728] text-4xl font-semibold font-['SF UI Display'] leading-[44px]">{props.scoreData.totalScore}</div>
          </div>
          {/* <div className="self-stretch text-center text-[#475466] text-sm font-medium font-['Poppins'] leading-tight">Updated Dec 2, 2024</div> */}
        </div>
      </div>
      <Accordion variant="splitted" className="px-0">
        {props.scoreData.features.map((feature: any, index: number) => (
          <AccordionItem
            key={index}
            title={
              <div className="flex justify-between items-center w-full">
                <span>{feature.name}</span>
                <span className="text-[#475466]">{feature.score}</span>
              </div>
            }
          >
            {feature.description}
            {feature.details && (
              <TableView data={feature.details} />
              // isArrayData(getNestedValue(props.scoreData, feature.details)) ? (
              //   <TableView data={getNestedValue(props.scoreData, feature.details)} />
              // ) : (
              //   <JsonFormViewer jsonData={getNestedValue(props.scoreData, feature.details)} />
              // )
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}

export function Score(props: ScoreProps) {
  const [scoreInfo, setScoreInfo] = useState({
    inputType: 'JSON',
    inputFile: null as File | null,
    inputData: null as object | null,
    scoreData: null as object | null,
    loading: false
  });
  const [scoreError, setScoreError] = useState<any>(null);
  const [getClaimScore] = useGetClaimScoreMutation();
  const [uploadFileTsetShowPdfoS3] = useUploadFileToS3Mutation();
  const [generatePresignedUrl] = useGeneratePresignedUrlMutation();
  const [getClaimScoreTableData] = useGetClaimScoreTableDataMutation();

  const normalizeScore = (value: number): number => {
    if (value > 0 && value < 1) {
      return Math.abs(Math.floor(value * 10));
    }
    return Math.abs(Math.floor(value));
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setScoreInfo(prev => ({ ...prev, loading: true, scoreData: null, inputFile: file }));
    setScoreError(null);
    try {
      // Read the file content using FileReader
      const fileContent = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.onerror = (e) => reject(new Error('Failed to read file'));
        reader.readAsText(file);
      });

      // Parse the JSON content
      const jsonData = JSON.parse(fileContent);

      const response = await generatePresignedUrl({
        "file_name": file.name,
      }).unwrap();

      const presignedUrl = response?.presigned_url;
      const unique_id = response?.unique_id;

      await uploadFileTsetShowPdfoS3({
        file: file,
        presignedUrl,
        CtyShowToast,
      }).unwrap();

      const jsonResponse = await getClaimScoreTableData({ unique_id }).unwrap();
      let partsArray: { Part: string; Quantity: number; }[] = [];
      if (jsonResponse && isArrayData((jsonResponse as any)?.['Estimated Parts with Quantity'])) {
        const partsObject = (jsonResponse as any)['Estimated Parts with Quantity'][0];
        partsArray = Object.entries(partsObject).map(([key, value]) => ({
          Part: key,
          Quantity: value as number
        }));
      }
      // Create a new object with all properties from jsonResponse plus the new parts array
      const processedResponse = {
        ...(typeof jsonResponse === 'object' ? jsonResponse : {}),
        parts: partsArray
      };

      const claimScore = await getClaimScore({
        provider_name: jsonData.requester.name,
        product_model: jsonData.serviceRequests[0].product[0].model,
        total_amount: parseFloat(jsonData.serviceRequests[0].totalAmount.totalAmount ?? 0),
        requested_labor_hours: jsonData.serviceRequests[0].totalAmount.requestedLaborHrs ?? 0,
        adjusted_labor_hours: jsonData.serviceRequests[0].totalAmount.adjustedLaborHrs ?? 0,
        entitlement_start_date: jsonData.serviceRequests[0].entitlement.startDate,
        repair_date: jsonData.serviceRequests[0].repairDate,
      }).unwrap();
      // Parse and normalize the main score
      let score = normalizeScore(parseFloat(claimScore.claim_score || '100'));

      // Calculate feature scores with normalization
      const feat_amt_Score = normalizeScore(parseFloat(claimScore.explanation.top_features[0].shap_value || '1'));
      const feat_pervin_score = normalizeScore(parseFloat(claimScore.explanation.top_features[1].shap_value || '1'));
      const feat_labor_score = normalizeScore(parseFloat(claimScore.explanation.top_features[2].shap_value || '1'));
      let feat_parts_score = score - (feat_amt_Score + feat_pervin_score + feat_labor_score);
      if (feat_parts_score < 0) {
        feat_parts_score = feat_parts_score + 10;
        score = score + feat_parts_score;
      }

      setScoreInfo(prev => ({
        ...prev, inputData: jsonData,
        scoreData: {
          totalScore: (jsonResponse as any)?.['Claims Score'].score,
          outputData: processedResponse,
          features: [
            { name: "Claim Dollars", description: `${(jsonResponse as any)?.['Claim Dollars'].description}: ${(jsonResponse as any)?.['Claim Dollars'].predicted}`, score: (jsonResponse as any)?.['Claim Dollars'].score },
            { name: "Claims per VIN", description: (jsonResponse as any)?.['Claims per VIN'].description, score: (jsonResponse as any)?.['Claims per VIN'].score },
            { name: "Labor Hours", description: `${(jsonResponse as any)?.['Labor Hours'].description}: ${(jsonResponse as any)?.['Labor Hours'].predicted}`, score: (jsonResponse as any)?.['Labor Hours'].score },
            { name: "Parts Used", description: `${(jsonResponse as any)?.['Parts Cost'].description}: ${(jsonResponse as any)?.['Parts Cost'].predicted}`, score: (jsonResponse as any)?.['Parts Cost'].score, details: (jsonResponse as any)?.['Parts Used']?.table },
          ]
        }
      }));
    } catch (error) {
      CtyShowToast('error', (error as any)?.message || 'Failed to process Score');
      setScoreError(error);
    } finally {
      setScoreInfo(prev => ({ ...prev, loading: false }));
    }
  };

  return (
    <div className="flex flex-col sm:w-[100%] md:w-auto lg:w-auto xl:w-auto bg-gray-50 h-full overflow-y-auto p-3 gap-3 lg:flex-row min-h-screen mb-4">
      {/* Viewer Section */}
      <div className="flex-1 lg:w-1/2 overflow-y-auto">
        <div className="bg-white rounded-lg shadow-sm p-4 lg:p-6 mb-4">
          <h1 className="text-xl lg:text-2xl font-bold text-gray-800 mb-4 lg:mb-6">Claim Input</h1>

          <div className="flex flex-col lg:flex-row gap-4 mb-4 lg:mb-6">
            <div className="w-full lg:flex-1 lg:min-w-[140px]">
              <Select
                label="Input Type"
                placeholder="Select Input Type"
                className="max-w-xs"
                onChange={(e) => setScoreInfo(prev => ({ ...prev, inputType: e.target.value }))}
                defaultSelectedKeys={[scoreInfo?.inputType]}
              >
                <SelectItem value="JSON" key={'JSON'} id="json-option">JSON</SelectItem>
                <SelectItem value="API" key={'API'} id="api-option">API</SelectItem>
              </Select>
            </div>

            <div className="w-full lg:flex-1 lg:self-end lg:w-[50%]">
              {!scoreInfo?.inputFile ? (
                <label className="flex flex-col items-center justify-center w-full p-6 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 hover:bg-blue-50 transition-colors">
                  <div className="flex flex-col items-center">
                    <Icon icon="material-symbols:upload" />
                    <p className="text-sm text-gray-600">
                      <span className="font-semibold">Upload JSON</span>
                    </p>
                    <p className="text-xs text-gray-500">JSON files only</p>
                  </div>
                  <Input
                    type="file"
                    id="upload-json-input"
                    accept="application/json"
                    onChange={handleFileUpload}
                    className="hidden"
                  />
                </label>
              ) : (
                <div className="flex items-center gap-3 p-3 bg-blue-50 rounded-lg">
                  <span className="flex-1 text-sm text-blue-700 truncate" title={scoreInfo?.inputFile.name}>
                    {scoreInfo?.inputFile.name?.length > 50
                      ? `${scoreInfo.inputFile.name.slice(0, 50)}...`
                      : scoreInfo?.inputFile.name}
                  </span>
                  <CtyButton
                    onPress={() => setScoreInfo(prev => ({
                      ...prev,
                      inputFile: null,
                      inputData: null,
                      scoreData: null
                    }))}
                    className="p-1 hover:bg-blue-100 rounded-full transition-colors min-w-0"
                    variant='flat'
                    color="white"
                  >
                    <Icon icon="radix-icons:cross-2" />
                  </CtyButton>
                </div>
              )}
            </div>
          </div>

          {scoreInfo?.inputData && (
            <div className="sm:min-h-[400px] bg-gray-50 rounded-lg overflow-auto lg:h-[70vh]">
              <JsonFormViewer jsonData={scoreInfo?.inputData} />
            </div>
          )}
        </div>
      </div>

      {/* Data Display Section */}
      <div className="flex-1 p-4 mb-16 lg:w-1/2 overflow-y-auto border-t lg:border-l lg:border-t-0 border-gray-200">
        {scoreError?.message ? <div>
          <h2 className="text-lg p-2 lg:text-xl font-semibold text-gray-800 lg:mb-6">Error</h2>
          <div className="rounded-lg shadow-sm bg-red-50 p-4 text-red-800">
            <p className="text-sm lg:text-base">{scoreError.message}</p>
          </div>
        </div> : <div className="rounded-lg shadow-sm relative bg-white">
          <h2 className="text-lg p-2 lg:text-xl font-semibold text-gray-800 lg:mb-6">Claim Score</h2>

          {scoreInfo?.loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-50">
              <CtySpinner size="lg" />
            </div>
          )}

          <div className="overflow-y-auto">
            {scoreInfo?.scoreData ? (
              <ScoreDecision scoreData={scoreInfo?.scoreData} inputData={scoreInfo?.inputData} />
            ) : !scoreInfo?.loading && scoreInfo?.inputFile ? (
              <div className="flex flex-col items-center justify-center py-8 text-gray-500">
                <p className="text-sm lg:text-base">Processing data...</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center py-8 text-gray-500">
                <p className="text-sm lg:text-base">Provide JSON or API inputs to see the score</p>
              </div>
            )}
          </div>
        </div>}
      </div>
    </div>
  );
}

export default Score;
