import { AppDispatch, speechToken, speechTokenApiSlice } from '@circuitry-ai/doc-data';
import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk';
import { azureSupportedLanguages } from './constants';

export class SpeechUtility {
    private static instance: SpeechUtility | undefined;
    private static tokenObj: speechToken = { token: '', region: '' };
    private readonly transrecognizer: speechsdk.TranslationRecognizer;
    private readonly transspeechConfig: speechsdk.SpeechTranslationConfig;
    private readonly recognizer: speechsdk.SpeechRecognizer;
    private readonly speechConfig: speechsdk.SpeechConfig;
    private myPlayer: speechsdk.SpeakerAudioDestination | undefined;
    private static language = 'en-US';
    public static languages: { [key: string]: string; } = azureSupportedLanguages;
    public static translateLanguages: { [key: string]: string; } = azureSupportedLanguages;
    // public static readonly languages: { [key: string]: string } = { "en-US": "English", "te-IN": "Telugu", "es-ES": "Spanish" };

    public static mapLanguages(newLanguages: string[]): { [key: string]: string } {
        return Object.entries(azureSupportedLanguages)
            .filter(([code, name]) => newLanguages.includes(name))
            .reduce((acc, [code, name]) => {
                acc[code] = name;
                return acc;
            }, {} as { [key: string]: string });
    }

    public static setLanguage(language: string) {
        SpeechUtility.language = language;
    }

    public static getLanguage() {
        return SpeechUtility.language;
    }
    public static updateTranslationLanguages(newLanguages: string[]) {
        const mappedLanguages = SpeechUtility.mapLanguages(newLanguages);
        SpeechUtility.translateLanguages = { ...mappedLanguages };
    }
    private constructor() {
        this.speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(SpeechUtility.tokenObj.token, SpeechUtility.tokenObj.region);
        this.speechConfig.speechRecognitionLanguage = SpeechUtility.language;
        const sttaudioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        this.recognizer = new speechsdk.SpeechRecognizer(this.speechConfig, sttaudioConfig);
        this.transspeechConfig = speechsdk.SpeechTranslationConfig.fromAuthorizationToken(SpeechUtility.tokenObj.token, SpeechUtility.tokenObj.region);
        this.transspeechConfig.speechRecognitionLanguage = SpeechUtility.language;
        this.transspeechConfig.addTargetLanguage("en");
        this.transrecognizer = new speechsdk.TranslationRecognizer(this.transspeechConfig, sttaudioConfig);
    }


    public static async getInstance(dispatch: AppDispatch): Promise<SpeechUtility> {
        if (!SpeechUtility.instance) {
            await (async () => {
                const { data } = await dispatch(speechTokenApiSlice.endpoints.getToken.initiate());
                if (data) SpeechUtility.tokenObj = data;
            })();
            SpeechUtility.instance = new SpeechUtility();
        }
        return SpeechUtility.instance;
    }

    public static destroyInstance() {
        if (SpeechUtility.instance) {
            SpeechUtility.instance.recognizer.close();
            SpeechUtility.instance = undefined;
        }
    }

    async sttFromMic(isListening: boolean, setIsListening: (isListening: boolean) => void, inputValue: string, setInputValue: (inputValue: string) => void) {
        const isTranslating = SpeechUtility.language !== 'en-US';
        if (isListening) {
            (isTranslating ? this.transrecognizer : this.recognizer).stopContinuousRecognitionAsync();
            setIsListening(false);
            return;
        }
        let silenceTimeoutId: NodeJS.Timeout, promptInput = inputValue ?? '', lastRecognizedPhrase = '', newRecognizedText = '';
        (isTranslating ? this.transrecognizer : this.recognizer).recognizing = (s: any, e: any) => {
            newRecognizedText = e.result.text.replace(lastRecognizedPhrase, '').trim();
            lastRecognizedPhrase = e.result.text;
            promptInput = `${promptInput} ${newRecognizedText}`;
            setInputValue(promptInput ?? '');

            // Clear the previous timeout and set a new one
            clearTimeout(silenceTimeoutId);
            silenceTimeoutId = setTimeout(() => {
                (isTranslating ? this.transrecognizer : this.recognizer).stopContinuousRecognitionAsync();
                setIsListening(false);
            }, 2000); // 2 seconds of silence
        };

        (isTranslating ? this.transrecognizer : this.recognizer).recognized = (s: any, e: any) => {
            if (e.result.text) {
                setInputValue(`${inputValue ?? ''} ${e.result.text}`.trim());
            }
        };

        (isTranslating ? this.transrecognizer : this.recognizer).canceled = (s: any, e: any) => {
            setInputValue('ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.');
        };

        (isTranslating ? this.transrecognizer : this.recognizer).startContinuousRecognitionAsync();
        setIsListening(true);
    }

    async textToSpeech(textToSpeak: string, setIsReading: (isReading: boolean) => void, setIsSpeechMode?: (isSpeechMode: boolean) => void) {
        setIsReading(true);
        this.myPlayer = new speechsdk.SpeakerAudioDestination();
        this.myPlayer.onAudioEnd = () => {
            setIsReading(false);
            setIsSpeechMode && setIsSpeechMode(false);
            this.myPlayer = undefined;
        };
        const ttsaudioConfig = speechsdk.AudioConfig.fromSpeakerOutput(this.myPlayer);
        const synthesizer = new speechsdk.SpeechSynthesizer(this.speechConfig, ttsaudioConfig);
        if (SpeechUtility.language !== 'en-US') {
            textToSpeak = await this.translateText(textToSpeak, SpeechUtility.language);
        }
        synthesizer.speakTextAsync(
            textToSpeak,
            result => {
                synthesizer.close();
            },
            err => {
                synthesizer.close();
            });
    }

    async handleMute(isReading: boolean, setIsReading: (isReading: boolean) => void) {
        if (isReading) {
            this.myPlayer?.pause();
            setIsReading(false);
        } else {
            this.myPlayer?.resume();
            setIsReading(true);
        }
    }

    async translateText(textToTranslate: string, targetLanguage: string): Promise<string> {
        const endpoint = `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&to=${targetLanguage}`;
        const headers = {
            'Ocp-Apim-Subscription-Key': '5229590d9c6343d98c81ccaf8ad877c0',
            'Ocp-Apim-Subscription-Region': 'global',
            'Content-type': 'application/json'
        };
        const body = JSON.stringify([{ 'text': textToTranslate }]);

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: headers,
                body: body
            });
            const data = await response.json();
            const translations = data[0].translations;
            return translations[0].text;
        } catch (error) {
            throw new Error('Translation failed: ' + error);
        }
    }

}