import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Navbar,
  NavbarBrand,
  NavbarContent,
} from '@nextui-org/react';
import { CtyButton } from '../button/CtyButton';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { checkType, CtyShowToast } from '../toast/CtyToast';
import { injectPalChatbot } from '../utils/commonutils';
import { useScreenWidth } from '../utiluicomponents/useScreenWidth';
import { CartItemsList } from '../utiluicomponents/cartItemsList';
export interface CtyHeaderProps {
  logo?: string;
  onOpen?: () => void;
  isCollapsed?: boolean;
}

export function CtyHeader(props: Readonly<CtyHeaderProps>) {
  const { onOpen, isCollapsed = false } = props;
  const navigate = useNavigate();
  const width = useScreenWidth();
  const isMobile = width < 640;

  // Function to dynamically inject the script and chatbot element

  const handleLaunchPAL = (e) => {
    e.preventDefault();
    const key = e.currentTarget.getAttribute('data-key');
    if (key === 'window_view') {
      const orgName = window.origin.split('//')[1]?.split('.')[0] || '';
      let palUrl;
      if (
        orgName.includes('localhost') ||
        orgName === 'app' ||
        orgName.includes('staging')
      ) {
        palUrl = 'https://pal.circuitry.ai/';
      } else {
        palUrl = `https://${orgName}.pal.circuitry.ai/`;
      }
      window.open(palUrl, '_blank', 'noopener,noreferrer');
    } else if (key === 'widget_view') {
      injectPalChatbot(CtyShowToast, checkType);
    }
  };
  return (
    <Navbar isBordered maxWidth="full" isBlurred={false} position="sticky">
      <div className="sm:hidden">
        <CtyButton
          isIconOnly
          className="flex  "
          size="sm"
          variant="light"
          onPress={onOpen}
        >
          <Icon
            className="text-default-500"
            height={24}
            icon="solar:hamburger-menu-outline"
            width={24}
          />
        </CtyButton>
      </div>
      {(isCollapsed || isMobile) && (
        <div>
          <NavbarBrand>
            <Image
              className="cursor-pointer"
              onClick={() => navigate('/')}
              src={props.logo}
              alt="Logo"
              width={150}
            />
          </NavbarBrand>
        </div>
      )}

      <NavbarContent as="div" justify="end" id="user-profile-btn">
        <Dropdown>
          <DropdownTrigger>
            <Button
              variant="bordered"
              size="lg"
              className="border-0 relative bg-gradient-to-r from-[#D4007E] to-[#003A79] p-[2px] text-[#475467] font-semibold hidden sm:block"
            >
              <span className="bg-white flex items-center text-sm justify-center w-full h-full rounded-medium px-3 text-gray-600  font-semibold leading-6 font-poppins">
                Launch PAL
              </span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions">
            <DropdownItem
              key="window_view"
              onClick={handleLaunchPAL}
              className="launch-pal"
            >
              New Window <Icon icon="radix-icons:open-in-new-window" />
            </DropdownItem>
            <DropdownItem
              key="widget_view"
              onClick={handleLaunchPAL}
              className="launch-pal"
            >
              Widget <Icon icon="solar:chat-line-linear" />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <CartItemsList />
      </NavbarContent>
    </Navbar>
  );
}

export default CtyHeader;
